import { ComponentSlider } from '@emico-react/component-slider'
import styled from '@emotion/styled'
import React, { ComponentProps } from 'react'

import { minWidth, maxWidth } from '@emico/styles'

import { CraftProductPageBlocksFragmentCraftUspsEntry } from '../lib/craftFragments.generated'
import theme from '../theme'
import CraftBlock from './CraftBlock'
import CraftProductUsp from './CraftProductUsp'
import SectionHeader from './SectionHeader'

const StyledCraftBlock = styled(CraftBlock)`
  padding-left: ${theme.containerPadding};
  padding-right: ${theme.containerPadding};
`

const UspsDesktop = styled.div`
  @media ${maxWidth('md')} {
    display: none;
  }
`

const Grid = styled('div', {
  shouldForwardProp: (prop) => !['itemCount'].includes(prop.toString()),
})<{
  itemCount: number
}>`
  display: grid;
  grid-template-columns: ${({ itemCount }) =>
    `repeat(${itemCount === 3 ? 3 : itemCount}, 1fr)`};
  grid-gap: ${theme.spacing.sm};

  @media ${minWidth('xl')} {
    grid-gap: ${theme.spacing.xl};
  }
`

interface SliderProps
  extends Omit<ComponentProps<typeof ComponentSlider>, 'children'> {
  usps: Exclude<
    CraftProductPageBlocksFragmentCraftUspsEntry['genericItems'],
    null
  >
}

const Slider = ({ usps, ...other }: SliderProps) => {
  if (!usps) {
    return null
  }

  return (
    <ComponentSlider snapAlign="start" slideGap={30} {...other}>
      {usps.map((usp, index) => {
        if (usp?.__typename !== 'CraftUspEntry') {
          return null
        }

        return <CraftProductUsp key={index} usp={usp} />
      })}
    </ComponentSlider>
  )
}

const SliderMobile = styled(Slider)`
  @media ${minWidth('md')} {
    display: none;
  }
`

const SliderTablet = styled(Slider)`
  @media ${maxWidth('sm')} {
    display: none;
  }

  @media ${minWidth('lg')} {
    display: none;
  }
`

interface Props {
  block: CraftProductPageBlocksFragmentCraftUspsEntry
}

const CraftProductUspsBlock = ({ block, ...other }: Props) => {
  if (!block.genericItems || block.genericItems.length === 0) {
    return null
  }

  return (
    <StyledCraftBlock {...other}>
      <SectionHeader title={block.blockTitle} hasLargeTitle />

      <SliderMobile usps={block.genericItems} slidesToShow={1.5} />

      <SliderTablet usps={block.genericItems} slidesToShow={2.5} />

      <UspsDesktop>
        <Grid itemCount={block.genericItems.length}>
          {block.genericItems.map((usp, index) => {
            if (usp?.__typename !== 'CraftUspEntry') {
              return null
            }

            return <CraftProductUsp key={index} usp={usp} />
          })}
        </Grid>
      </UspsDesktop>
    </StyledCraftBlock>
  )
}

export default CraftProductUspsBlock

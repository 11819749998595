import styled from '@emotion/styled'
import React from 'react'

import ImageSwatch from './ImageSwatch'
import NextLink from './NextLink'

const StyledNextLink = styled(NextLink, {
  shouldForwardProp: (prop) => !['isDisabled'].includes(prop.toString()),
})<{ isDisabled?: boolean }>`
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
`

interface Props {
  name: string | undefined | null
  url: string | undefined | null
  image: string | undefined | null
  isCurrent: boolean
  isSalable: boolean
}

const ColorVariant = ({ name, url, image, isCurrent, isSalable }: Props) =>
  name && url && image ? (
    <StyledNextLink
      href={url}
      analyticsContext="colorVariant"
      analyticsName={name}
      isDisabled={isCurrent}
    >
      <ImageSwatch
        image={image}
        alt={name}
        isSelected={isCurrent}
        isDisabled={!isSalable}
      />
    </StyledNextLink>
  ) : null

export default ColorVariant

import styled from '@emotion/styled'
import React, { forwardRef, InputHTMLAttributes, Ref } from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'

import { getDefaultErrorMessage } from '../lib/formErrorMessages'
import theme from '../theme'
import FormField from './FormField'
import InputError from './InputError'
import InputLabel, { Label } from './InputLabel'
import TextArea from './TextArea'

const StyledFormField = styled(FormField)`
  position: relative;
`

const InputWrapper = styled.div`
  display: flex;

  &:focus-within {
    outline: 1px solid currentColor;
    outline-offset: -4px;
  }
`

const StyledTextArea = styled(TextArea, {
  shouldForwardProp: (prop) => !['hasError'].includes(prop.toString()),
})<{ hasError?: boolean }>`
  width: 100%;
  padding: ${theme.spacing.sm};
  border: ${theme.borders.default};
  border-color: ${({ hasError }) => hasError && theme.colors.error};
  transition-property: ${theme.transition.properties.colors};
  transition-duration: ${theme.transition.durations.extraSlow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};

  &::placeholder {
    opacity: 0;
  }

  &:disabled {
    background-color: ${theme.colors.backgroundLight};
    cursor: not-allowed;
    color: ${theme.colors.textMuted};
  }

  &:focus {
    box-shadow: none;
    outline: 0;
  }

  // Apply styling when input is focussed or has value
  &:focus,
  &:not(:placeholder-shown) {
    + ${Label} {
      transform: scale(0.8) translateY(-50%);
    }
  }
`

/**
 * Text area component
 */

interface Props<T extends FieldValues>
  extends InputHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Pass control from parent in order to get textarea fieldState (`const { control } = useForm()`)
   */
  control: Control<T>
  /**
   * Textarea label
   */
  label: string
  /**
   * Custom error message
   */
  customErrorMessage?: string
  /**
   * Type fix, passed from useForm's register
   */
  name: Path<T>
  /**
   * Number of rows the textarea should have
   */
  rows?: number
}

const InputTextArea = <T extends FieldValues>(
  {
    control,
    label,
    customErrorMessage,
    rows = 4,
    className,
    ...other
  }: Props<T>,
  ref: Ref<HTMLTextAreaElement>,
) => {
  const { fieldState } = useController({
    name: other.name,
    control: control,
  })

  /**
   * Error message:
   * - If error from fieldState occurs, show error
   * - If no error from fieldState occurs, but customErrorMessage exists, show custom error
   * (e.g. when hook result error state returns after input registration validation)
   * - If no message is added to fieldState error, and no customErrorMessage exists, show default error based on error type
   */
  const errorMessage =
    fieldState.error?.message ??
    customErrorMessage ??
    (fieldState.error
      ? getDefaultErrorMessage(fieldState.error.type)
      : undefined)

  const hasError = Boolean(fieldState.error)
  const hasErrorMessage = Boolean(errorMessage)

  return (
    <StyledFormField fieldIdentifier={other.name ?? ''} className={className}>
      <InputWrapper>
        <StyledTextArea
          id={other.name}
          ref={ref}
          hasError={hasError}
          placeholder={label}
          rows={rows}
          {...other}
        />

        <InputLabel>{label}</InputLabel>
      </InputWrapper>

      {hasErrorMessage && errorMessage && <InputError message={errorMessage} />}
    </StyledFormField>
  )
}

export default forwardRef(InputTextArea) as typeof InputTextArea

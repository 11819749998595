import { ProductPageStaticData } from '../pages/product/useProduct'

export default (
  craftData: Exclude<ProductPageStaticData['productContentData'], null> | null,
) => {
  if (!craftData || !('productPageBlocks' in craftData)) {
    return {
      beforeProductDataBlocks: [],
      afterProductDataBlocks: [],
    }
  }

  const blockSeparationBlockIndex =
    craftData.productPageBlocks?.findIndex(
      (block) => block?.__typename === 'CraftBlockSeparationEntry',
    ) || -1

  const beforeProductDataBlocks =
    blockSeparationBlockIndex !== undefined && blockSeparationBlockIndex !== -1
      ? craftData.productPageBlocks?.slice(0, blockSeparationBlockIndex)
      : blockSeparationBlockIndex === -1
      ? craftData.productPageBlocks
      : []

  const afterProductDataBlocks =
    blockSeparationBlockIndex !== undefined && blockSeparationBlockIndex !== -1
      ? craftData.productPageBlocks?.slice(blockSeparationBlockIndex + 1)
      : []

  return {
    beforeProductDataBlocks,
    afterProductDataBlocks,
  }
}

import styled from '@emotion/styled'
import { Plural } from '@lingui/macro'
import React from 'react'

import theme from '../theme'

const StarCount = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 70px auto 30px;
  gap: ${theme.spacing.sm};
`

const StarsTotalsMeter = styled.div`
  background-color: ${theme.colors.grayLight};
  height: 9px;
  border-radius: 5px;
  overflow: hidden;
`

const StarsTotalsMeterBar = styled('div', {
  shouldForwardProp: (prop) => !['width'].includes(prop.toString()),
})<{ width?: number }>`
  width: ${({ width = 0 }) => width}%;
  height: 100%;
  background-color: ${theme.colors.yellow};
`

interface StarValues {
  one: number | null
  two: number | null
  three: number | null
  four: number | null
  five: number | null
}

interface Props {
  starsTotals: StarValues
  reviewsCount: number
}

const ReviewRatingStarTotals = ({
  starsTotals,
  reviewsCount,
  ...other
}: Props) => {
  const starValues = [
    { key: 5, value: Number(starsTotals.five) },
    { key: 4, value: Number(starsTotals.four) },
    { key: 3, value: Number(starsTotals.three) },
    { key: 2, value: Number(starsTotals.two) },
    { key: 1, value: Number(starsTotals.one) },
  ]

  return (
    <div {...other}>
      {starValues.map(({ key, value }) => {
        const barWidth = reviewsCount ? (value / reviewsCount) * 100 : 0

        return (
          <StarCount key={key}>
            <Plural value={key} one="# star" other="# stars" />

            <StarsTotalsMeter>
              <StarsTotalsMeterBar width={barWidth}></StarsTotalsMeterBar>
            </StarsTotalsMeter>

            {value}
          </StarCount>
        )
      })}
    </div>
  )
}

export default ReviewRatingStarTotals

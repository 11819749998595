import React from 'react'

import CategoryLayout from '../../../components/CategoryLayout'
import { DefaultLayoutStaticData } from '../../../lib/useCraftGlobalSets'
import { CategoryLandingPageStaticData } from './useCategoryLanding'

const CategoryPage = ({
  websiteData,
  disableEcommerce,
  rootCategoryData,
  categoryData,
  tweakwiseData,
  craftDataCategoryLanding,
  craftDataCategoryLandingParentCategory,
  craftDataFilterExplanations,
  resolvedRoute,
}: CategoryLandingPageStaticData & DefaultLayoutStaticData) => {
  if (!categoryData) {
    return null
  }

  const initialLandingPageFacetParam =
    'initialFacets' in resolvedRoute
      ? resolvedRoute.initialFacets.replace('?facets=', '')
      : undefined

  const parentCategoryContent =
    craftDataCategoryLandingParentCategory?.__typename === 'CraftCategoryEntry'
      ? craftDataCategoryLandingParentCategory
      : undefined

  const categoryLandingContent =
    craftDataCategoryLanding?.__typename === 'CraftCategoryLandingEntry'
      ? craftDataCategoryLanding
      : undefined

  return (
    <CategoryLayout
      websiteData={websiteData}
      disableEcommerce={disableEcommerce}
      categoryData={categoryData}
      rootCategoryTweakwiseItemNumber={rootCategoryData.tweakwiseItemNumber}
      tweakwiseItemNumber={categoryData.tweakwiseItemNumber}
      tweakwiseData={tweakwiseData}
      craftDataCategory={parentCategoryContent}
      craftDataCategoryLanding={categoryLandingContent}
      craftDataFilterExplanations={craftDataFilterExplanations}
      initialLandingPageFacetParam={initialLandingPageFacetParam}
    />
  )
}

export default CategoryPage

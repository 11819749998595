import { ProductFragment } from '@emico-hooks/product-fragment'

import { AmbianceGallery, ProductImage } from '@emico/graphql-schema-types'
import { sortArrayByProperty, stripMaybes } from '@emico/utils'

import { GalleryItem, ValidAmbianceImage, ValidMediaImage } from './customTypes'

const isValidMediaImage = (
  image:
    | Pick<ProductImage, 'disabled' | 'url'>
    | Pick<AmbianceGallery, 'disabled' | 'url'>
    | null
    | undefined,
): boolean => Boolean(image && !image.disabled && image.url)

export interface MediaItems {
  mediaItems: GalleryItem[]
  ambianceItems: ValidAmbianceImage[]
}

export default (product: ProductFragment) => {
  const galleryItems: GalleryItem[] | undefined = product?.mediaGallery?.filter(
    (image): image is ValidMediaImage => isValidMediaImage(image),
  )

  const ambianceItems: ValidAmbianceImage[] | undefined =
    product?.ambianceGallery?.filter((image): image is ValidAmbianceImage =>
      isValidMediaImage(image),
    )

  const sortedGalleryItems =
    galleryItems && galleryItems.length > 0
      ? sortArrayByProperty(galleryItems, (galleryItem) =>
          galleryItem &&
          'position' in galleryItem &&
          (galleryItem.position || galleryItem.position === 0)
            ? galleryItem.position
            : null,
        )
      : isValidMediaImage(product?.smallImage)
      ? [product?.smallImage as ValidMediaImage]
      : []

  const sortedAmbianceItems = sortArrayByProperty(
    ambianceItems ?? [],
    (ambianceItem) => ambianceItem.position ?? null,
  )

  return {
    galleryItems: sortedGalleryItems.filter(stripMaybes),
    ambianceItems: sortedAmbianceItems.filter(stripMaybes),
  }
}

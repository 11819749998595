import {
  useQuery,
  gql,
  TypedDocumentNode,
  QueryHookOptions,
} from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import {
  AdvReviewQuery,
  AdvReviewQueryVariables,
} from './useAdvReview.generated'

enum AmastyReviewSortValue {
  TopRated = 'top_rated',
  Newest = 'newest',
}

enum AmastyReviewDirValue {
  ASC = 'ASC',
  DESC = 'DESC',
}

const amastyImagesFragment = gql`
  fragment AmastyImagesFragment on Images {
    fullPath
    resizedPath
  }
`

const amastyRatingVoteFragment = gql`
  fragment AmastyRatingVoteFragment on RatingVote {
    ratingId
    ratingCode
    optionId
    value
  }
`

const amastyReviewFragment = gql`
  fragment AmastyReviewFragment on Review {
    reviewId
    nickname
    createdAt
    title
    detail
    verifiedBuyer

    product {
      uid
      name
    }

    ratingVotes {
      ...AmastyRatingVoteFragment
    }

    images {
      ...AmastyImagesFragment
    }
  }

  ${amastyRatingVoteFragment}
  ${amastyImagesFragment}
`

export const advReviewQuery = gql`
  query AdvReviewQuery(
    $productUid: ID!
    $page: Int!
    $sort: String
    $dir: String
  ) {
    advreview(
      productUid: $productUid
      page: $page
      amreviewSort: $sort
      amreviewDir: $dir
    ) {
      productUid
      ratingSummary
      ratingSummaryValue
      totalRecords

      detailedSummary {
        one
        two
        three
        four
        five
      }

      items {
        ...AmastyReviewFragment
      }
    }
  }

  ${amastyReviewFragment}
` as TypedDocumentNode<AdvReviewQuery, AdvReviewQueryVariables>

/**
 * Query to get ReviewsData object
 *
 * @returns ReviewsData object to access a product's reviews
 *
 * Project usage example:
 *
 * ```
 * const Component = () => {
 *  const { data: advReview } = useAdvReview(product.uid, currentPage, reviewSort)
 *
 *  [...]
 * }
 * ```
 */

export function useAdvReview(
  productUid: string,
  page?: number,
  sort?: string,
  dir?: AmastyReviewDirValue,
  options?: QueryHookOptions<AdvReviewQuery, AdvReviewQueryVariables>,
) {
  const { data, error, loading, ...others } = useQuery(advReviewQuery, {
    variables: {
      productUid,
      page: page ?? 1,
      sort: sort ?? AmastyReviewSortValue.Newest,
      dir: dir ?? AmastyReviewDirValue.DESC,
    },
    context: getCacheableContext(),
    ...options,
  })

  return {
    ...others,
    loading,
    data: data?.advreview ?? undefined,
    error,
  }
}

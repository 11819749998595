import styled from '@emotion/styled'
import React, { forwardRef, InputHTMLAttributes, Ref } from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'

import { Input as BaseInput } from '@emico/input'

import { getDefaultErrorMessage } from '../lib/formErrorMessages'
import FormField from './FormField'
import InputError from './InputError'

const StyledFormField = styled(FormField)`
  position: relative;
`

/**
 * Input range type component
 */
interface Props<T extends FieldValues>
  extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Pass control from parent in order to get input fieldState (`const { control } = useForm()`)
   */
  control: Control<T>
  /**
   * Type fix, passed from useForm's register
   */
  name: Path<T>
}

const InputRange = <T extends FieldValues>(
  { control, className, ...other }: Props<T>,
  ref: Ref<HTMLInputElement>,
) => {
  const { fieldState } = useController({
    name: other.name,
    control: control,
  })

  /**
   * Error message:
   * - If error from fieldState occurs, show error
   * - If no message is added to fieldState error, show default error based on error type
   */

  const errorMessage =
    fieldState.error?.message ??
    (fieldState.error
      ? getDefaultErrorMessage(fieldState.error.type)
      : undefined)

  return (
    <>
      <StyledFormField fieldIdentifier={other.name ?? ''} className={className}>
        <BaseInput id={other.name} type="range" ref={ref} {...other} />
      </StyledFormField>

      {errorMessage && <InputError message={errorMessage} />}
    </>
  )
}

InputRange.displayName = 'InputRange'

export default forwardRef(InputRange) as typeof InputRange

/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { ReviewOrder } from '@emico/graphql-schema-types'

import theme from '../theme'
import Select from './Select'

const Form = styled.form`
  display: flex;
  align-items: center;
`

const Label = styled.label`
  margin: 0;
  margin-right: ${theme.spacing.md};
  font-weight: ${theme.fontWeights.bold};
`

const StyledSelect = styled(Select as any)`
  flex-grow: 1;
` as typeof Select

interface FormValues {
  sortKey: string
}

interface Props {
  sortOptions: ReviewOrder[]
  sortOrder?: string
  setSortOrder: (sortOrder: string) => void
}

const ReviewsSortForm = ({
  sortOptions,
  sortOrder,
  setSortOrder,
  ...other
}: Props) => {
  const { register, control, handleSubmit, watch, reset, formState } =
    useForm<FormValues>()
  const sortKeyValue = watch('sortKey')
  const defaultSortOption = sortOrder ?? sortOptions?.[0].code

  const onSubmit = useCallback(
    (values: FormValues) => {
      setSortOrder(values.sortKey)
    },
    [setSortOrder],
  )

  // Reset form with defaultValues including defaultSortOption, because defaultSortOption is not available on initial render
  useEffect(() => {
    if (defaultSortOption) {
      setSortOrder(defaultSortOption)
      reset({
        sortKey: defaultSortOption,
      })
    }
  }, [reset, defaultSortOption, setSortOrder])

  // Submit form on sortKeyValue change
  useEffect(() => {
    if (
      sortKeyValue &&
      formState.isDirty &&
      sortKeyValue !== defaultSortOption
    ) {
      onSubmit({ sortKey: sortKeyValue })
    }
  }, [defaultSortOption, formState.isDirty, onSubmit, sortKeyValue])

  return (
    <Form onSubmit={handleSubmit(onSubmit)} {...other}>
      <Label htmlFor="sortKey">
        <Trans>Sort by:</Trans>
      </Label>

      <StyledSelect
        control={control}
        defaultValue={defaultSortOption}
        {...register('sortKey')}
      >
        <>
          {sortOptions.map((option) => (
            <option key={option.code} value={option.code}>
              {option.label}
            </option>
          ))}
        </>
      </StyledSelect>
    </Form>
  )
}

export default ReviewsSortForm

import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { GetStaticPaths, GetStaticPathsResult, GetStaticProps } from 'next'

import { UrlRewriteEntityTypeEnum } from '@emico/graphql-schema-types'

import {
  addApolloState,
  createApolloClient,
  initializeApollo,
} from '../apollo/apolloClient'
import DefaultLayout from '../components/DefaultLayout'
import { CraftTypeHandleEnum } from '../lib/CraftTypeHandleEnum'
import getMagentoPaths from '../lib/getMagentoPaths'
import resolveRoute from '../lib/resolveRoute'
import { RouteQuery } from '../lib/resolveRoute.generated'
import { StoreConfigFragment } from '../lib/storeConfig.generated'
import {
  DefaultLayoutStaticData,
  getStaticProps as getDefaultLayoutStaticProps,
} from '../lib/useCraftGlobalSets'
import { getStaticProps as getCraftNotFoundPageStaticProps } from '../lib/useCraftNotFoundPage'
import storeConfigs from '../storeConfig.json'
import NotFoundPage from './404.page'
import CategoryPage from './category'
import CategoryLandingPage from './category/landing'
import {
  CategoryLandingPageStaticData,
  getStaticProps as getCategoryLandingPageStaticProps,
} from './category/landing/useCategoryLanding'
import {
  CategoryPageStaticData,
  getStaticProps as getCategoryPageStaticProps,
} from './category/useCategory'
import CraftEntrySwitch from './craft'
import {
  CraftBlogPostStaticData,
  getStaticProps as getCraftBlogPostPageStaticProps,
} from './craft/blog/post/useBlogPost'
import {
  CraftBlogStaticData,
  getStaticProps as getCraftBlogPageStaticProps,
} from './craft/blog/useBlog'
import {
  CraftCustomerServiceSubjectStaticData,
  getStaticProps as getCraftCustomerServiceSubjectPageStaticProps,
} from './craft/customer-service/subject/useCustomerServiceSubject'
import {
  CraftCustomerServiceOverviewStaticData,
  getStaticProps as getCraftCustomerServiceOverviewPageStaticProps,
} from './craft/customer-service/useCustomerServiceOverview'
import {
  CraftInformationDetailStaticData,
  getStaticProps as getCraftInformationDetailPageStaticProps,
} from './craft/information/detail/useInformationDetail'
import {
  CraftInformationSubjectStaticData,
  getStaticProps as getCraftInformationOverviewPageStaticProps,
} from './craft/information/subject/useInformationSubject'
import {
  CraftInformationStaticData,
  getStaticProps as getCraftInformationPageStaticProps,
} from './craft/information/useInformation'
import {
  CraftPageStaticData,
  getStaticProps as getCraftPageStaticProps,
} from './craft/page/usePage'
import {
  CraftRequestLicensePlateStaticData,
  getStaticProps as getCraftRequestLicensePlatePageStaticProps,
} from './craft/request-license-plate/useRequestLicensePlate'
import {
  CraftStoreLocatorStoreStaticData,
  getStaticProps as getCraftStoreLocatorStorePageStaticProps,
} from './craft/store-locator/store/useStore'
import {
  CraftStoreLocatorStaticData,
  getStaticProps as getCraftStoreLocatorPageStaticProps,
} from './craft/store-locator/useStoreLocator'
import ProductPage from './product'
import {
  ProductPageStaticData,
  getStaticProps as getProductPageStaticProps,
} from './product/useProduct'

export type PageTypePageProps =
  | ProductPageStaticData
  | CraftBlogStaticData
  | CraftBlogPostStaticData
  | CategoryPageStaticData
  | CategoryLandingPageStaticData
  | CraftPageStaticData
  | CraftCustomerServiceOverviewStaticData
  | CraftCustomerServiceSubjectStaticData
  | CraftStoreLocatorStaticData
  | CraftStoreLocatorStoreStaticData
  | CraftInformationStaticData
  | CraftInformationSubjectStaticData
  | CraftInformationDetailStaticData
  | CraftRequestLicensePlateStaticData

export interface RouteProps {
  resolvedRoute: RouteQuery['route'] | null | undefined
  initialApolloState?: NormalizedCacheObject
  client?: ApolloClient<unknown>
  storeConfig?: StoreConfigFragment
  pageProps?: DefaultLayoutStaticData & PageTypePageProps
}

function findStoreConfigByLocale(configs: typeof storeConfigs, locale: string) {
  return configs.storeViews.find((view) => view.locale === locale)
}

/**
 * Here we generate all the paths that should be pre-rendered
 */
export const getStaticPaths: GetStaticPaths = async ({
  defaultLocale,
  locales,
}) => {
  if (!process.env.CI_DEPLOY_PASS_TWO) {
    // We build this app in two passes, first build we don't prerender any pages
    // In the second build we prerender pages.
    return {
      paths: [],
      fallback: 'blocking',
    }
  }

  if (!process.env.MAGENTO_SERVER_URL) {
    throw new Error('MAGENTO_SERVER_URL is not set.')
  }

  const paths: GetStaticPathsResult['paths'] = []

  // Each storeView could have different urls.
  for (const locale of locales ?? [defaultLocale]) {
    if (!locale) {
      continue
    }

    const storeConfig = findStoreConfigByLocale(storeConfigs, locale)
    const storeCode = storeConfig?.code ?? 'default'
    const client = createApolloClient(storeCode, locale, storeConfig?.storeId)

    if (!storeConfig?.storeId) {
      continue
    }

    paths.push(
      ...(await getMagentoPaths(storeConfig?.storeId, locale, client, true)),
    )
  }

  return {
    paths: [...paths],
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps = async ({
  params,
  locale,
  defaultLocale,
}) => {
  if (locale === 'global') {
    return {
      notFound: true,
    }
  }

  const client = initializeApollo(locale ?? defaultLocale ?? '')

  const urlPath =
    (Array.isArray(params?.slug) ? params?.slug?.join('/') : params?.slug) ??
    '/'
  // Also serve homepage for base urls without trailing slash
  const languageCountryRegex = new RegExp('^(/([a-z]){2}){2}$')
  const isOnlyNL = urlPath === '/nl' || urlPath === '/nl/'
  const { data: resolvedRoute } = await resolveRoute(client)(
    urlPath === '/' || languageCountryRegex.test(urlPath ?? '') || isOnlyNL
      ? '__home__'
      : urlPath ?? '/',
  )

  const { websiteData } = await getDefaultLayoutStaticProps(client, locale)

  // For each page type, get the nested static props.
  let pageTypePageProps: PageTypePageProps | undefined = undefined

  let revalidate = 60 * 5

  // If route does not exist: use 404.page.tsx
  if (!resolvedRoute?.route?.type) {
    return {
      notFound: true,
      revalidate: 60,
    }
  }

  switch (resolvedRoute.route?.type) {
    case UrlRewriteEntityTypeEnum.PRODUCT:
      revalidate = 60

      pageTypePageProps = await getProductPageStaticProps(
        client,
        resolvedRoute['route'],
        locale,
      )

      if (
        'productData' in pageTypePageProps &&
        !pageTypePageProps.productData.productByUid
      ) {
        return {
          notFound: true,
          revalidate: 60,
        }
      }

      break
    case UrlRewriteEntityTypeEnum.CATEGORY:
      pageTypePageProps = await getCategoryPageStaticProps(
        client,
        resolvedRoute['route'],
        locale,
      )
      break
    case UrlRewriteEntityTypeEnum.LANDINGPAGE:
      pageTypePageProps = await getCategoryLandingPageStaticProps(
        client,
        resolvedRoute['route'],
        locale,
      )
      break
    case UrlRewriteEntityTypeEnum.CRAFT:
      revalidate = 60 * 15
      // For each craft entry type, get the corresponding nested static props.
      switch (resolvedRoute.route.entryType) {
        case CraftTypeHandleEnum.HOMEPAGE:
        case CraftTypeHandleEnum.PAGE:
          pageTypePageProps = await getCraftPageStaticProps(
            client,
            resolvedRoute['route'],
            locale,
          )
          break
        case CraftTypeHandleEnum.BLOG:
          pageTypePageProps = await getCraftBlogPageStaticProps(
            client,
            resolvedRoute['route'],
            locale,
          )
          break
        case CraftTypeHandleEnum.BLOG_POST:
          pageTypePageProps = await getCraftBlogPostPageStaticProps(
            client,
            resolvedRoute['route'],
            locale,
          )
          break
        case CraftTypeHandleEnum.CUSTOMER_SERVICE_OVERVIEW:
          pageTypePageProps =
            await getCraftCustomerServiceOverviewPageStaticProps(
              client,
              resolvedRoute['route'],
              locale,
            )
          break
        case CraftTypeHandleEnum.CUSTOMER_SERVICE_SUBJECT:
          pageTypePageProps =
            await getCraftCustomerServiceSubjectPageStaticProps(
              client,
              resolvedRoute['route'],
              locale,
            )
          break
        case CraftTypeHandleEnum.STORE_LOCATOR:
          pageTypePageProps = await getCraftStoreLocatorPageStaticProps(
            client,
            resolvedRoute['route'],
            locale,
          )
          break
        case CraftTypeHandleEnum.STORE:
          pageTypePageProps = await getCraftStoreLocatorStorePageStaticProps(
            client,
            resolvedRoute['route'],
            locale,
          )
          break
        case CraftTypeHandleEnum.INFORMATION_OVERVIEW:
          pageTypePageProps = await getCraftInformationPageStaticProps(
            client,
            resolvedRoute['route'],
            locale,
          )
          break
        case CraftTypeHandleEnum.INFORMATION_OVERVIEW_GO_CARTS:
        case CraftTypeHandleEnum.INFORMATION_OVERVIEW_TRAMPOLINES:
        case CraftTypeHandleEnum.INFORMATION_OVERVIEW_RIDE_ON_CARS:
        case CraftTypeHandleEnum.INFORMATION_OVERVIEW_BALANCE_BIKES:
        case CraftTypeHandleEnum.INFORMATION_OVERVIEW_SCOOTERS:
        case CraftTypeHandleEnum.INFORMATION_OVERVIEW_PLAYGROUND_EQUIPMENT:
          pageTypePageProps = await getCraftInformationOverviewPageStaticProps(
            client,
            resolvedRoute['route'],
            locale,
          )
          break
        case CraftTypeHandleEnum.INFORMATION_GO_CARTS:
        case CraftTypeHandleEnum.INFORMATION_TRAMPOLINES:
        case CraftTypeHandleEnum.INFORMATION_RIDE_ON_CARS:
        case CraftTypeHandleEnum.INFORMATION_BALANCE_BIKES:
        case CraftTypeHandleEnum.INFORMATION_SCOOTERS:
        case CraftTypeHandleEnum.INFORMATION_PLAYGROUND_EQUIPMENT:
          pageTypePageProps = await getCraftInformationDetailPageStaticProps(
            client,
            resolvedRoute['route'],
            locale,
          )
          break
        case CraftTypeHandleEnum.REQUEST_LICENSE_PLATE:
          pageTypePageProps = await getCraftRequestLicensePlatePageStaticProps(
            client,
            resolvedRoute['route'],
            locale,
          )
          break
        default:
          pageTypePageProps = await getCraftNotFoundPageStaticProps(
            client,
            locale,
          )

          return {
            notFound: true,
            revalidate: 60,
          }
      }
      break
    default:
      pageTypePageProps = await getCraftNotFoundPageStaticProps(client, locale)

      return {
        notFound: true,
        revalidate: 60,
      }
  }

  const pageProps = {
    websiteData,
    ...(pageTypePageProps ?? {}),
  }

  return addApolloState(client, {
    props: {
      resolvedRoute: resolvedRoute.route,
      pageProps,
    },
    // After 60sec the page will be re-rendered in the background.
    revalidate,
  })
}

const RouteResolver = ({
  resolvedRoute,
  pageProps,
  storeConfig,
}: RouteProps) => {
  const isHomepage =
    pageProps?.resolvedRoute?.entryType === CraftTypeHandleEnum.HOMEPAGE

  const hasPagePaddingBottom =
    pageProps &&
    'craftDataPage' in pageProps &&
    pageProps.craftDataPage &&
    'hasPagePaddingBottom' in pageProps.craftDataPage
      ? pageProps.craftDataPage.hasPagePaddingBottom ?? undefined
      : true

  const hasPagePaddingTop =
    (pageProps &&
      'craftDataPage' in pageProps &&
      pageProps.craftDataPage &&
      'hasPagePaddingTop' in pageProps.craftDataPage &&
      pageProps.craftDataPage.hasPagePaddingTop) ??
    undefined

  // Render page based on resolvedRoute type
  switch (resolvedRoute?.type) {
    case UrlRewriteEntityTypeEnum.PRODUCT:
      if (!pageProps || !('productData' in pageProps)) {
        return (
          <NotFoundPage resolvedRoute={resolvedRoute} pageProps={pageProps} />
        )
      }

      return (
        <DefaultLayout
          websiteData={pageProps.websiteData}
          hasPaddingTop={false}
          hasPaddingBottom={false}
        >
          <ProductPage
            {...pageProps}
            key={'uid' in resolvedRoute ? resolvedRoute.uid : undefined}
          />
        </DefaultLayout>
      )
    case UrlRewriteEntityTypeEnum.CATEGORY:
      if (!pageProps || !('categoryData' in pageProps)) {
        return (
          <NotFoundPage resolvedRoute={resolvedRoute} pageProps={pageProps} />
        )
      }

      return (
        <DefaultLayout
          websiteData={pageProps.websiteData}
          hasPaddingTop={false}
          showMetaCanonical
        >
          <CategoryPage {...pageProps} />
        </DefaultLayout>
      )
    case UrlRewriteEntityTypeEnum.LANDINGPAGE:
      if (!pageProps || !('categoryData' in pageProps)) {
        return (
          <NotFoundPage resolvedRoute={resolvedRoute} pageProps={pageProps} />
        )
      }

      return (
        <DefaultLayout
          websiteData={pageProps.websiteData}
          hasPaddingTop={false}
          showMetaCanonical
        >
          <CategoryLandingPage {...pageProps} />
        </DefaultLayout>
      )
    case UrlRewriteEntityTypeEnum.CRAFT:
      if (!pageProps) {
        return (
          <NotFoundPage resolvedRoute={resolvedRoute} pageProps={pageProps} />
        )
      }

      return (
        <DefaultLayout
          websiteData={pageProps?.websiteData}
          hasPaddingBottom={!isHomepage && hasPagePaddingBottom}
          hasPaddingTop={!isHomepage && hasPagePaddingTop}
        >
          <CraftEntrySwitch
            pageProps={pageProps}
            resolvedRoute={resolvedRoute}
          />
        </DefaultLayout>
      )
    default:
      return (
        <NotFoundPage resolvedRoute={resolvedRoute} pageProps={pageProps} />
      )
  }
}

export default RouteResolver

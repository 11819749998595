import { ButtonUnstyled } from '@emico-react/buttons'
import { Image } from '@emico-react/image'
import styled from '@emotion/styled'
import React, { useState } from 'react'

import { minWidth } from '@emico/styles'

import theme from '../theme'
import ImageSliderModal from './ImageSliderModal'

const getImageCount = (imageUrls: string[], visibleImageCount: number) => {
  if (imageUrls.length >= visibleImageCount) {
    return visibleImageCount
  }

  return imageUrls.length
}

const Row = styled('div', {
  shouldForwardProp: (prop) => !['visibleImageCount'].includes(prop.toString()),
})<{ visibleImageCount: number }>`
  display: grid;
  gap: ${theme.spacing.sm};
  grid-template-columns: ${({ visibleImageCount }) =>
    `repeat(${visibleImageCount}, 1fr)`};
`

const StyledButtonUnstyled = styled(ButtonUnstyled)`
  position: relative;
  margin-bottom: 0;
`

const StyledImage = styled(Image)`
  max-width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`

const ShowMoreOverlay = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: rgba(0, 0, 0, 0.6);
  color: ${theme.colors.textLight};
  font-size: ${theme.fontSizes.lg};

  @media ${minWidth('md')} {
    font-size: ${theme.fontSizes['3xl']};
  }
`

interface Props {
  /**
   * List of image urls
   */
  imageUrls: string[]
  /**
   * How many images should be visible in the row?
   */
  visibleImageCount?: number
}

const ImageRow = ({ imageUrls, visibleImageCount = 7, ...other }: Props) => {
  const [showImageModal, setShowImageModal] = useState<boolean>(false)
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0)

  const nextImageIndex = visibleImageCount - 1
  const nextImageCount = imageUrls.length - nextImageIndex
  const visibleImages = imageUrls.slice(
    0,
    getImageCount(imageUrls, visibleImageCount),
  )

  const handleClick = (index: number) => {
    setCurrentSlideIndex(index)
    setShowImageModal(true)
  }

  return (
    <>
      <Row visibleImageCount={visibleImageCount} {...other}>
        {visibleImages?.map((url, index) => (
          <StyledButtonUnstyled
            key={`${url}-${index}`}
            analyticsContext="image.row"
            analyticsName="open.image.modal"
            onPress={() => handleClick(index)}
          >
            <StyledImage
              url={url}
              alt="review-image"
              sizes={theme.imageSizes.square.sizes}
              lazy={false}
            />

            {index === nextImageIndex && (
              <ShowMoreOverlay>+ {nextImageCount}</ShowMoreOverlay>
            )}
          </StyledButtonUnstyled>
        ))}
      </Row>

      <ImageSliderModal
        show={showImageModal}
        close={() => setShowImageModal(false)}
        imageUrls={imageUrls}
        currentSlideIndex={currentSlideIndex}
        setCurrentSlideIndex={setCurrentSlideIndex}
      />
    </>
  )
}

export default ImageRow

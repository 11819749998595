import { useCart } from '@emico-hooks/cart'
import { ProductCardFragment } from '@emico-hooks/product-card-fragment'
import { Image } from '@emico-react/image'
import styled from '@emotion/styled'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import { CheckmarkRoundIcon } from '@emico/icons'
import { minWidth, maxWidth } from '@emico/styles'
import { H2 } from '@emico/ui'
import { stripMaybes } from '@emico/utils'

import { ProductFragment } from '../packages/product-fragment'
import theme from '../theme'
import ButtonPrimary from './ButtonPrimary'
import ButtonSecondary from './ButtonSecondary'
import Modal, { Content as ModalContent } from './Modal'
import PreselectOptionsList from './PreselectOptionsList'
import ProductSliderWithTracking from './ProductSliderWithTracking'
import RegularFinalPrice from './RegularFinalPrice'
import SectionHeader from './SectionHeader'

function getSlidesToShowTablet(productCount: number) {
  switch (productCount) {
    case 1:
    case 2:
      return 2
    default:
      return 2.5
  }
}

function getSlidesToShowDesktop(productCount: number) {
  switch (productCount) {
    case 1:
    case 2:
      return 2
    default:
      return 3
  }
}

const StyledModal = styled(Modal)`
  ${ModalContent} {
    padding-bottom: 0;

    @media ${minWidth('lg')} {
      padding-bottom: ${theme.spacing['2xl']};
    }
  }
`

const StyledCheckmarkRoundIcon = styled(CheckmarkRoundIcon)`
  margin-right: ${theme.spacing.sm};
  font-size: 25px;
  color: ${theme.colors.success};
`

const Content = styled.div`
  padding-top: ${theme.spacing.lg};
  border-top: ${theme.borders.default};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
`

const Product = styled.div`
  display: grid;
  grid-gap: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    grid-template-columns: 175px auto;
    grid-gap: ${theme.spacing.xl};
  }
`

const Figure = styled.figure`
  margin: 0;
  background-color: ${theme.colors.backgroundLight};
`

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  max-height: 175px;
  object-fit: contain;

  @media ${minWidth('sm')} {
    aspect-ratio: 1 / 1;
  }
`

const ProductTitle = styled(H2)`
  font-size: ${theme.fontSizes.md};
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: ${theme.spacing.sm};

  @media ${minWidth('md')} {
    font-size: ${theme.fontSizes.lg};
  }
`

const StyledPreselectOptionsList = styled(PreselectOptionsList)`
  margin-bottom: ${theme.spacing.md};
`

const Grid = styled.div`
  display: grid;

  @media ${minWidth('md')} {
    grid-template-columns: auto auto;
    grid-gap: ${theme.spacing.xl};
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: ${theme.colors.background};
  padding-bottom: ${theme.spacing.lg};
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  margin-bottom: ${theme.spacing.sm};
`

const CrossSellProducts = styled.div`
  margin-bottom: ${theme.spacing.lg};
  margin-top: ${theme.spacing.lg};
  padding-top: ${theme.spacing.lg};
  border-top: ${theme.borders.default};

  @media ${minWidth('md')} {
    padding-top: ${theme.spacing['2xl']};
  }
`

interface ButtonsProps {
  close: () => void
}

const Buttons = ({ close, ...other }: ButtonsProps) => (
  <ButtonsWrapper {...other}>
    <StyledButtonPrimary
      analyticsContext="cross.sell.modal"
      analyticsName="cart"
      colorType="green"
      href="/cart"
    >
      <Trans>Go to shopping cart</Trans>
    </StyledButtonPrimary>

    <ButtonSecondary
      analyticsContext="cross.sell.modal"
      analyticsName="continue"
      colorTheme="dark"
      onClick={close}
    >
      <Trans>Continue shopping</Trans>
    </ButtonSecondary>
  </ButtonsWrapper>
)

const StyledButtonsMobileAndTablet = styled(Buttons)`
  position: sticky;
  bottom: 0;
  z-index: 1;

  @media ${minWidth('lg')} {
    display: none;
  }
`

const StyledButtonsDesktop = styled(Buttons)`
  @media ${maxWidth('md')} {
    display: none;
  }
`

interface Props {
  product: ProductFragment
  crossSellProducts?: ProductCardFragment[]
  show: boolean
  close: () => void
}

const CrossSellModal = ({ product, crossSellProducts, show, close }: Props) => {
  const { data: cart } = useCart()
  const crossSellProductCount = crossSellProducts?.length ?? 0
  const crossSellSectionTitle = t({ message: 'Often purchased together with' })

  const cartItem = cart?.items.find((item) => item?.product.uid === product.uid)

  const itemProductParentData =
    cartItem?.product && 'parentData' in cartItem.product
      ? cartItem.product.parentData
      : undefined

  const productParentPreselectOptions =
    itemProductParentData?.preselectOptions?.filter(stripMaybes) ?? []

  return (
    <StyledModal
      title={
        <>
          <StyledCheckmarkRoundIcon />
          <Trans>Added to shopping cart</Trans>
        </>
      }
      show={show}
      close={close}
    >
      <Content>
        <div>
          <Grid>
            <Product>
              {product?.image?.url && (
                <Figure>
                  <StyledImage
                    url={product.image.url}
                    alt={product.smallImage?.label ?? product.name}
                    lazy={false}
                    width={theme.imageSizes.productCard.width}
                  />
                </Figure>
              )}

              <div>
                {product?.name && <ProductTitle>{product.name}</ProductTitle>}

                {productParentPreselectOptions.length !== 0 && (
                  <StyledPreselectOptionsList
                    options={productParentPreselectOptions}
                  />
                )}

                <RegularFinalPrice
                  finalPrice={product?.priceRange.minimumPrice.finalPrice}
                  regularPrice={product?.priceRange.minimumPrice.regularPrice}
                  percentOff={
                    product?.priceRange.minimumPrice.discount?.percentOff
                  }
                />
              </div>
            </Product>

            <StyledButtonsDesktop close={close} />
          </Grid>

          {crossSellProducts && crossSellProductCount !== 0 && (
            <CrossSellProducts>
              <SectionHeader title={crossSellSectionTitle} hasLargeTitle />

              <ProductSliderWithTracking
                disableEcommerce={false}
                products={crossSellProducts}
                slidesToShowMobile={crossSellProductCount === 1 ? 1 : 1.25}
                slidesToShowTablet={getSlidesToShowTablet(
                  crossSellProductCount,
                )}
                slidesToShowDesktop={getSlidesToShowDesktop(
                  crossSellProductCount,
                )}
                trackingInfo={{
                  itemListId: 'related_products',
                  itemListName: crossSellSectionTitle,
                }}
                showAddToCartButton
              />
            </CrossSellProducts>
          )}
        </div>

        <StyledButtonsMobileAndTablet close={close} />
      </Content>
    </StyledModal>
  )
}

export default CrossSellModal

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React from 'react'

import { H3 } from '@emico/ui'

import { ProductFragment } from '../packages/product-fragment'
import theme from '../theme'
import AttributesTable from './AttributesTable'
import SideModal from './SideModal'

const AttributesSection = styled.div`
  margin-bottom: ${theme.spacing.xl};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const AttributesSectionTitle = styled(H3)`
  margin-bottom: ${theme.spacing.sm};
  font-weight: ${theme.fontWeights.medium};
  text-transform: uppercase;
`

interface Props
  extends Pick<
    ProductFragment,
    'highlightedAttributes' | 'dynamicAttributes' | 'productDownloads'
  > {
  disableEcommerce: boolean
  product: ProductFragment
  show: boolean
  close: () => void
}

const AttributesModal = ({
  disableEcommerce,
  product,
  highlightedAttributes,
  dynamicAttributes,
  productDownloads,
  show,
  close,
}: Props) => {
  // Remove highlightedAttributes from dynamicAttributes
  // To prevent double attributes in tables
  const attributes = dynamicAttributes?.filter(
    (attribute) =>
      !highlightedAttributes?.find(
        (highlightedAttribute) =>
          highlightedAttribute?.attributeLabel === attribute?.attributeLabel,
      ),
  )

  const isMainProduct =
    product?.isMainProduct || product?.__typename === 'ConfigurableProduct'

  return (
    <SideModal
      disableEcommerce={disableEcommerce}
      title={<Trans>Dimensions and details</Trans>}
      show={show}
      close={close}
      product={product}
      showProductPrice={!isMainProduct}
      showCartButton
    >
      {highlightedAttributes && highlightedAttributes?.length !== 0 && (
        <AttributesSection>
          <AttributesTable attributes={highlightedAttributes} />
        </AttributesSection>
      )}

      <AttributesSection>
        <AttributesSectionTitle>
          <Trans>Features</Trans>
        </AttributesSectionTitle>

        <AttributesTable attributes={attributes} />
      </AttributesSection>

      {productDownloads && productDownloads.downloads?.length !== 0 && (
        <AttributesSection>
          <AttributesSectionTitle>
            {productDownloads.title}
          </AttributesSectionTitle>

          <AttributesTable attributes={productDownloads.downloads} />
        </AttributesSection>
      )}
    </SideModal>
  )
}

export default AttributesModal

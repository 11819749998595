import styled from '@emotion/styled'
import React from 'react'

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

interface PreselectOption {
  uid: string
  attributeCode: string
  label: string
  value: string
}

interface Props {
  options: PreselectOption[]
}

const PreselectOptionsList = ({ options, ...other }: Props) => (
  <List {...other}>
    {options.map((option) => (
      <li key={option.uid}>
        {option.label}&#58; {option.value}
      </li>
    ))}
  </List>
)

export default PreselectOptionsList

import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import BodyScrollLock from '@emico/body-scroll-lock'
import { Modal } from '@emico/modal'
import ModalBackdrop from '@emico/modal-backdrop'
import { ModalSegueFade } from '@emico/modal-segue'
import { ButtonUnstyled } from '@emico/ui'

import CrossRoundIcon from '../icons/CrossRoundIcon'
import theme from '../theme'

const FullScreenContent = styled.section`
  width: 100%;
  overflow: auto;
  background-color: ${theme.colors.background};
  position: relative;
`

const ContentWrapper = styled.div`
  height: 100vh;
`

const TopBar = styled('div', {
  shouldForwardProp: (prop) => !['hasAbsoluteHeader'].includes(prop.toString()),
})<{ hasAbsoluteHeader?: boolean }>`
  height: ${theme.sizes.fullScreenModalHeaderHeight};
  position: ${({ hasAbsoluteHeader }) => hasAbsoluteHeader && 'absolute'};
  right: ${theme.spacing.lg};
  top: ${theme.spacing.xs};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const StyledButtonUnstyled = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => !['hasAbsoluteHeader'].includes(prop.toString()),
})<{ hasAbsoluteHeader?: boolean }>`
  width: ${theme.sizes.fullScreenModalHeaderHeight},
  height: ${theme.sizes.fullScreenModalHeaderHeight}
  color: ${theme.colors.primary};
  padding: ${theme.spacing.sm};
  z-index: 1;
`

const StyledCrossRoundIcon = styled(CrossRoundIcon)`
  color: ${theme.colors.primary};
  width: 32px;
  height: 32px;
`

interface Props {
  /**
   * show modal state
   */
  show: boolean
  /**
   * close modal function
   */
  close: () => void
  /**
   * Modal's children, passed by parent component
   */
  children: ReactNode
  /**
   * Should the modal header be absolute positioned?
   */
  hasAbsoluteHeader?: boolean
  /**
   * Should the modal render before it is shown?
   */
  renderBeforeShow?: boolean
}

const FullScreenModal = ({
  show,
  close,
  children,
  hasAbsoluteHeader,
  renderBeforeShow = false,
  ...other
}: Props) => (
  <>
    <ModalBackdrop show={show} onClick={close} />

    <ModalSegueFade show={show} renderBeforeShow={renderBeforeShow}>
      <Modal close={close} show={show}>
        <FullScreenContent>
          <TopBar hasAbsoluteHeader={hasAbsoluteHeader}>
            <StyledButtonUnstyled
              analyticsContext="fullScreen.modal"
              analyticsName="close"
              onClick={close}
            >
              <StyledCrossRoundIcon />
            </StyledButtonUnstyled>
          </TopBar>

          <BodyScrollLock lock={show}>
            <ContentWrapper {...other}>{children}</ContentWrapper>
          </BodyScrollLock>
        </FullScreenContent>
      </Modal>
    </ModalSegueFade>
  </>
)

export default FullScreenModal

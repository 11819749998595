import { ButtonUnstyled } from '@emico-react/buttons'
import styled from '@emotion/styled'
import React from 'react'

import { H1 } from '@emico/ui'

import theme from '../theme'
import StarRatingWithTotals from './StarRatingWithTotals'

const Title = styled(H1)`
  margin-bottom: ${theme.spacing.xs};
  font-size: ${theme.fontSizes['2xl']};
`

const StyledButtonUnstyled = styled(ButtonUnstyled)`
  text-decoration: underline;
`

interface Props {
  title: string
  reviewRating: number
  reviewsCount: number
  showReviewsModal: boolean
  setShowReviewsModal: (showReviewsModal: boolean) => void
}

const ProductPageHeader = ({
  title,
  reviewRating,
  reviewsCount,
  showReviewsModal,
  setShowReviewsModal,
  ...other
}: Props) => (
  <header {...other}>
    <Title>{title}</Title>

    <StyledButtonUnstyled
      analyticsContext="reviews"
      analyticsName="open.reviews.modal"
      onPress={() => setShowReviewsModal(true)}
    >
      <StarRatingWithTotals value={reviewRating} totalRecords={reviewsCount} />
    </StyledButtonUnstyled>
  </header>
)

export default ProductPageHeader

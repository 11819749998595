import { ProductFragment } from '@emico-hooks/product-fragment'
import React from 'react'
import { Product as ProductSchema } from 'schema-dts'

import { ProductStockStatus } from '@emico/graphql-schema-types'

import { useStoreConfig } from '../lib/storeConfig'
import SchemaJsonLd from './SchemaJsonLd'

type Props = Partial<ProductSchema> & {
  product: ProductFragment
}

const SchemaProduct = ({ product, brand, ...other }: Props) => {
  const { storeConfig } = useStoreConfig()

  if (!storeConfig) {
    return null
  }

  if (!product.canonicalUrl) {
    console.warn(
      'Magento 2 is not configured properly: "Use Canonical Link Meta Tag For Products" must be enabled.' +
        'Currently the Open Graph urls and ids are rendered via the urlKey which may result in duplicate content.',
    )
  }

  const isMainProduct =
    product?.isMainProduct || product?.__typename === 'ConfigurableProduct'
  const fallbackImage = product.mediaGallery?.[0]?.url
  const price = product.priceRange.minimumPrice.finalPrice

  return (
    <SchemaJsonLd<ProductSchema>
      item={{
        '@context': 'https://schema.org',
        '@type': 'Product',
        '@id': storeConfig.baseUrl + (product.canonicalUrl ?? product.urlKey),
        name: product.name,
        image: product.smallImage?.url ?? fallbackImage ?? '',
        url: storeConfig.baseUrl + (product.canonicalUrl ?? product.urlKey),
        description:
          product.metaDescription ?? product.description?.html ?? undefined,
        sku: product.sku,
        offers: !isMainProduct
          ? {
              '@type': 'Offer',
              priceCurrency: price.currency,
              price: price.value,
              availability:
                product.stockStatus === ProductStockStatus.IN_STOCK
                  ? 'https://schema.org/InStock'
                  : 'https://schema.org/OutOfStock',
              itemCondition: 'https://schema.org/NewCondition',
            }
          : undefined,
        aggregateRating:
          product.ratingSummary &&
          product.reviewCount &&
          product.reviewCount > 0
            ? {
                '@type': 'AggregateRating',
                worstRating: 1,
                bestRating: 5,
                ratingValue: product.ratingSummary,
                reviewCount: product.reviewCount,
              }
            : undefined,
        ...other,
      }}
    />
  )
}

export default SchemaProduct

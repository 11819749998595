import React from 'react'

import { Maybe } from '@emico/graphql-schema-types'

import { CraftCategoryProductPageBlocksFragment } from '../lib/craftFragments.generated'
import CraftHtmlBlock from './CraftHtmlBlock'
import CraftImageWithProductOverviewBlock from './CraftImageWithProductOverviewBlock'

const renderBlock = (
  block: CraftCategoryProductPageBlocksFragment,
  index: number,
) => {
  switch (block.__typename) {
    case 'CraftCategoryImageWithProductOverviewEntry':
      return <CraftImageWithProductOverviewBlock key={index} block={block} />
    case 'CraftHtml2Entry':
      return <CraftHtmlBlock key={index} block={block} />
    default:
      console.warn(`Craft block type "${block.__typename}" is not implemented`)

      return process.env.NODE_ENV === 'development' ? (
        <div key={index}>
          Craft block type "{block.__typename}" is not implemented
        </div>
      ) : null
  }
}

interface Props {
  blocks: Array<Maybe<CraftCategoryProductPageBlocksFragment>>
}

const CraftCategoryProductPageBlocks = ({ blocks }: Props) => {
  if (!blocks || blocks.length === 0) {
    return null
  }

  return (
    <>
      {blocks.map((block, index) => {
        if (!block) {
          return null
        }

        return renderBlock(block, index)
      })}
    </>
  )
}

export default CraftCategoryProductPageBlocks

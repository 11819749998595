import { Image } from '@emico-react/image'
import styled from '@emotion/styled'
import React from 'react'

import theme from '../theme'

const Figure = styled('figure', {
  shouldForwardProp: (prop) =>
    !['isSelected', 'isDisabled'].includes(prop.toString()),
})<{ isSelected?: boolean; isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 0;
  padding: ${theme.spacing.xxs};
  background-color: ${theme.colors.grayLight};
  border: ${theme.borders.base};
  border-color: ${({ isSelected }) =>
    isSelected ? theme.colors.grayDark : 'transparent'};
  border-width: 0 0 2px 0;
  min-height: 50px;

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    left: 0;
    top: 50%;
    transform: rotate(-45deg) translate(0%, 50%);
    background-color: ${theme.colors.grayBrown};
    display: ${({ isDisabled }) => !isDisabled && 'none'};
  }
`

const StyledImage = styled(Image, {
  shouldForwardProp: (prop) => !['isDisabled'].includes(prop.toString()),
})<{ isDisabled?: boolean }>`
  opacity: ${({ isDisabled }) => isDisabled && 0.4};
  width: 100%;
  height: auto;
`

interface Props {
  image: string
  alt: string
  isSelected?: boolean
  isDisabled?: boolean
}

const ImageSwatch = ({ image, alt, isSelected, isDisabled }: Props) => (
  <Figure isSelected={isSelected} isDisabled={isDisabled}>
    <StyledImage
      url={image}
      alt={alt}
      width={170}
      height={120}
      lazy={false}
      isDisabled={isDisabled}
    />
  </Figure>
)

export default ImageSwatch

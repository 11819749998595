import { Youtube } from '@emico-react/youtube'
import styled from '@emotion/styled'
import React from 'react'

import theme from '../theme'
import FullScreenModal from './FullScreenModal'

const StyledFullScreenModal = styled(FullScreenModal)`
  display: flex;
  flex-direction: column;
`

const StyledYoutube = styled(Youtube)`
  width: 100vw;
  max-height: calc(100vh - ${theme.sizes.fullScreenModalHeaderHeight});
`

interface Props {
  /**
   * show modal state
   */
  show: boolean
  /**
   * close modal function
   */
  close: () => void
  /**
   * Youtube videoId
   */
  videoId?: string
}

const VideoModal = ({ show, close, videoId }: Props) => {
  if (!videoId) {
    return null
  }

  return (
    <StyledFullScreenModal show={show} close={close}>
      <StyledYoutube videoId={videoId} autoPlay />
    </StyledFullScreenModal>
  )
}

export default VideoModal

import React from 'react'

import CategoryLayout from '../../components/CategoryLayout'
import CategoryNoResults from '../../components/CategoryNoResults'
import Meta from '../../components/Meta'
import getSeoPageTitle from '../../lib/getSeoPageTitle'
import { DefaultLayoutStaticData } from '../../lib/useCraftGlobalSets'
import { CategoryPageStaticData } from './useCategory'

const CategoryPage = ({
  websiteData,
  disableEcommerce,
  categoryData,
  rootCategoryData,
  tweakwiseData,
  craftDataCategory,
  craftDataCategoryGeneric,
  craftDataFilterExplanations,
}: CategoryPageStaticData & DefaultLayoutStaticData) => {
  if (!categoryData) {
    return null
  }

  const resultsCount = tweakwiseData.properties.nrOfItems
  const categoryContent =
    craftDataCategory?.__typename === 'CraftCategoryEntry'
      ? craftDataCategory
      : undefined

  if (resultsCount === 0) {
    return (
      <>
        <Meta
          metaTitle={
            categoryData.metaTitle ?? getSeoPageTitle(categoryData.name)
          }
          metaDescription={categoryData.metaDescription ?? categoryData.name}
        />

        <CategoryNoResults
          categoryName={categoryData.name}
          craftDataCategoryGeneric={craftDataCategoryGeneric}
        />
      </>
    )
  }

  return (
    <CategoryLayout
      websiteData={websiteData}
      disableEcommerce={disableEcommerce}
      categoryData={categoryData}
      rootCategoryTweakwiseItemNumber={rootCategoryData.tweakwiseItemNumber}
      tweakwiseItemNumber={categoryData.tweakwiseItemNumber}
      tweakwiseData={tweakwiseData}
      craftDataCategory={categoryContent}
      craftDataFilterExplanations={craftDataFilterExplanations}
    />
  )
}

export default CategoryPage

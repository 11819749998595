import { ProductFragment } from '@emico-hooks/product-fragment'
import { ButtonUnstyled } from '@emico-react/buttons'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import { ChevronRightIcon } from '@emico/icons'
import { maxWidth, minWidth } from '@emico/styles'
import { H3 } from '@emico/ui'

import { useAdvReview } from '../lib/useAdvReview'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import { ReviewQuery } from '../pages/product/useProduct.generated'
import theme from '../theme'
import ButtonSecondary from './ButtonSecondary'
import CreateReviewModal from './CreateReviewModal'
import ImageRow from './ImageRow'
import Review from './Review'
import ReviewsModal from './ReviewsModal'
import SectionHeader from './SectionHeader'
import SectionTitle from './SectionTitle'
import StarRatingWithTotals from './StarRatingWithTotals'

const StyledSectionHeader = styled(SectionHeader)`
  margin-bottom: ${theme.spacing.xl};

  @media ${minWidth('md')} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`

const TitleWrapper = styled.div`
  margin-bottom: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    margin-bottom: 0;
  }
`

const StyledButtonSecondary = styled(ButtonSecondary)`
  flex-shrink: 0;
  margin-left: ${theme.spacing.sm};
`

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: ${theme.spacing.xs};
`

const StyledH3 = styled(H3)`
  margin-bottom: ${theme.spacing.md};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;

  @media ${minWidth('md')} {
    font-size: ${theme.fontSizes.lg};
  }
`

const StyledImageRow = styled(ImageRow)`
  margin-bottom: ${theme.spacing.xl};
`

const StyledImageRowMobile = styled(StyledImageRow)`
  @media ${minWidth('md')} {
    display: none;
  }
`

const StyledImageRowTabletAndDesktop = styled(StyledImageRow)`
  @media ${maxWidth('sm')} {
    display: none;
  }
`

const ReviewList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ReviewListItem = styled.li`
  border-bottom: ${theme.borders.default};

  &:first-of-type {
    border-top: ${theme.borders.default};
  }
`

const StyledButtonUnstyled = styled(ButtonUnstyled)`
  text-decoration: underline;
`

const ReviewsModalButton = styled(ButtonUnstyled)`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.lg};
  text-decoration: underline;
  font-weight: ${theme.fontWeights.bold};
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  margin-left: ${theme.spacing.xs};
  font-size: 14px;
`

interface RouterQuery {
  query: {
    flow?: string
  }
}

interface Props extends DefaultLayoutStaticData {
  disableEcommerce: boolean
  product: ProductFragment
  showReviewsModal: boolean
  setShowReviewsModal: (showReviewsModal: boolean) => void
  reviewContentData: Exclude<ReviewQuery['entry'], null> | null
  hasMainProduct?: boolean
}

const Reviews = ({
  websiteData,
  disableEcommerce,
  product,
  showReviewsModal,
  setShowReviewsModal,
  reviewContentData,
  hasMainProduct,
}: Props) => {
  const { query }: RouterQuery = useRouter()
  const flowParam = query.flow
  const { data: reviewsData } = useAdvReview(product.uid)
  const [showCreateReviewModal, setShowCreateReviewModal] =
    useState<boolean>(false)

  const totalRecords = reviewsData?.totalRecords ?? 0
  const visibleReviews = reviewsData?.items?.slice(0, 4) ?? []
  const allReviewsImages =
    reviewsData?.items
      ?.flatMap((review) => review?.images?.map((image) => image?.fullPath))
      .filter(stripMaybes) ?? []

  const disclaimersGlobalSet = websiteData?.find(
    (globalSet) => globalSet?.__typename === 'CraftDisclaimersGlobalGlobalSet',
  )

  const submitReviewDisclaimer =
    disclaimersGlobalSet?.__typename === 'CraftDisclaimersGlobalGlobalSet'
      ? disclaimersGlobalSet.richTextDisclaimerReview
      : undefined

  useEffect(() => {
    if (flowParam === 'review') {
      setShowCreateReviewModal(true)
    }
  }, [flowParam])

  return (
    <>
      <StyledSectionHeader>
        <TitleWrapper>
          <StyledSectionTitle
            title={<Trans>Reviews {product.name}</Trans>}
            hasLargeTitle
          />

          {totalRecords === 0 ? (
            <StarRatingWithTotals
              value={product.ratingSummary ?? 0}
              totalRecords={product.reviewCount ?? 0}
            />
          ) : (
            <StyledButtonUnstyled
              analyticsContext="reviews"
              analyticsName="open.reviews.modal"
              onPress={() => setShowReviewsModal(true)}
            >
              <StarRatingWithTotals
                value={product.ratingSummary ?? 0}
                totalRecords={product.reviewCount ?? 0}
              />
            </StyledButtonUnstyled>
          )}
        </TitleWrapper>

        <StyledButtonSecondary
          analyticsContext="reviews"
          analyticsName="write.review"
          colorTheme="dark"
          onClick={() => setShowCreateReviewModal(true)}
        >
          <Trans>Write a review</Trans>
        </StyledButtonSecondary>
      </StyledSectionHeader>

      {allReviewsImages.length !== 0 && (
        <>
          <StyledH3>
            <Trans>Customer images</Trans>
          </StyledH3>

          <StyledImageRowMobile
            imageUrls={allReviewsImages}
            visibleImageCount={4}
          />

          <StyledImageRowTabletAndDesktop
            imageUrls={allReviewsImages}
            visibleImageCount={5}
          />
        </>
      )}

      {visibleReviews.length !== 0 && (
        <>
          <StyledH3>
            <Trans>Most recent reviews</Trans>
          </StyledH3>

          <ReviewList>
            {visibleReviews.map((review) =>
              review ? (
                <ReviewListItem key={review.reviewId}>
                  <Review review={review} hasMainProduct={hasMainProduct} />
                </ReviewListItem>
              ) : null,
            )}
          </ReviewList>
        </>
      )}

      {totalRecords > visibleReviews.length && (
        <ReviewsModalButton
          analyticsContext="reviews"
          analyticsName="show.all.reviews"
          onPress={() => setShowReviewsModal(true)}
        >
          <Trans>Show all {totalRecords} reviews</Trans>

          <StyledChevronRightIcon />
        </ReviewsModalButton>
      )}

      <ReviewsModal
        disableEcommerce={disableEcommerce}
        show={showReviewsModal}
        close={() => setShowReviewsModal(false)}
        product={product}
        ratingAverage={reviewsData?.ratingSummaryValue ?? 0}
        reviewsCount={reviewsData?.totalRecords ?? 0}
        detailedSummary={reviewsData?.detailedSummary}
        allReviewsImages={allReviewsImages}
        setShowCreateReviewModal={setShowCreateReviewModal}
        hasMainProduct={hasMainProduct}
      />

      <CreateReviewModal
        disableEcommerce={disableEcommerce}
        show={showCreateReviewModal}
        close={() => setShowCreateReviewModal(false)}
        product={product}
        reviewContentData={reviewContentData}
        submitReviewDisclaimer={submitReviewDisclaimer}
      />
    </>
  )
}

export default Reviews

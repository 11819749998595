/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonUnstyled } from '@emico-react/buttons'
import styled from '@emotion/styled'
import { Trans, t } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  FormInputImagePreview,
  FormInputImageStyled,
  useImageInputState,
} from '@emico/form-input-image'
import { H3, Loader } from '@emico/ui'

import StarIcon from '../icons/StarIcon'
import getAmastyReviewRatings from '../lib/ getAmastyReviewRatings'
import getBase64StringFromFile from '../lib/getBase64StringFromFile'
import getFilesSizeInBytes from '../lib/getFilesSizeInBytes'
import { AddAdvReviewInput, useAddAdvReview } from '../lib/useAddAdvReview'
import { useAmReviewSetting } from '../lib/useAmReviewSetting'
import theme from '../theme'
import Alert from './Alert'
import FormFieldsEmail from './FormFieldsEmail'
import FormHelpText from './FormHelpText'
import HtmlContent from './HtmlContent'
import Input from './Input'
import InputRange from './InputRange'
import InputTextArea from './InputTextArea'
import { StyledButtonPrimary } from './LoginForm'

const maxBodySizeMb = process.env.NEXT_PUBLIC_UPLOAD_REVIEW_IMAGE_SIZE_LIMIT_MB
const maxBodySizeBytes = Math.floor(
  parseInt(maxBodySizeMb ? maxBodySizeMb : '4') * Math.pow(1024, 2),
)

const StyledH3 = styled(H3)`
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: ${theme.spacing.sm};
  text-transform: uppercase;
`

const Fieldset = styled.fieldset`
  margin-bottom: ${theme.spacing.xl};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledButtonUnstyled = styled(ButtonUnstyled)`
  margin-right: ${theme.spacing.xs};

  &:last-of-type {
    margin-right: 0;
  }
`

const StyledInputRange = styled(InputRange as any)`
  visibility: hidden;
  height: 0;
  width: 0;
`

const StyledFormInputImageStyled = styled(FormInputImageStyled)`
  display: block;

  + label {
    margin-bottom: 0;
  }
`

const StyledStarIcon = styled(StarIcon, {
  shouldForwardProp: (prop) =>
    !['isActive', 'hasError'].includes(prop.toString()),
})<{ isActive?: boolean; hasError?: boolean }>`
  font-size: 23px;
  color: ${({ isActive }) =>
    isActive ? theme.colors.yellow : theme.colors.grayMiddle};
  stroke: ${({ hasError }) => hasError && theme.colors.error};
`

const StyledAlert = styled(Alert)`
  margin: ${theme.spacing.lg} 0;
`

const DisclaimerTextWrapper = styled.div`
  margin-top: ${theme.spacing.lg};
`

const ValidInputCheckBox = styled.input`
  display: none;
`

interface UploadProps {
  reviewImageData: string | ArrayBuffer | undefined
  reviewImageName: string
}

interface UploadImageInput {
  hasValidUpload: boolean
}

interface Props {
  productUid: string
  onSuccess: () => void
  submitReviewDisclaimer?: string | null
}

const CreateReviewForm = ({
  productUid,
  onSuccess,
  submitReviewDisclaimer,
}: Props) => {
  const { state: imageState, handleChange, handleRemove } = useImageInputState()
  const { data: amReviewSetting } = useAmReviewSetting()
  const { submitReview, isSubmitting, isSuccess, error } = useAddAdvReview()
  const { handleSubmit, control, register, watch, setValue, formState } =
    useForm<AddAdvReviewInput & UploadImageInput>({
      mode: 'onBlur',
      defaultValues: {
        hasValidUpload: true,
      },
    })

  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>()
  const [uploadError, setUploadError] = useState<Error | undefined>(undefined)
  const [submitError, setSubmitError] = useState<Error | undefined>(undefined)
  const [uploadData, setUploadData] = useState<UploadProps[] | undefined>(
    undefined,
  )

  const ratingsValue = watch('ratings')
  const overallRating = amReviewSetting?.ratings?.[0]
  const ratingOptions = overallRating?.ratingOptions
  const ratingStarCount = overallRating?.ratingOptions?.length ?? 0

  const errorMessages = {
    required: {
      ratings: t({
        message: 'Choose a number of stars',
      }),
      title: t({
        message: 'Enter a review title',
      }),
      nickname: t({
        message: 'Enter a nickname',
      }),
      detail: t({
        message: 'Enter an experience',
      }),
    },
    validation: {
      detail: t({
        message: 'Enter a minimum of 4 characters',
      }),
    },
  }

  const ratingsError = formState.errors.ratings?.message

  useEffect(() => {
    if (isSuccess) {
      onSuccess()
    }
  }, [isSuccess, onSuccess])

  /**
   * On input change: store array of upload objects in state:
   * array of objects with base64 string and file name.
   */
  const handleInputImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    handleChange(event)
    const files = event.target.files
    const fileList = files ? Array.from(files) : []

    try {
      setUploadError(undefined)
      setIsLoadingUpload(true)
      const uploadList = fileList?.map(async (file) => {
        const uploadResult = await getBase64StringFromFile(file).then(
          (response) => response,
        )

        return {
          reviewImageData: uploadResult,
          reviewImageName: file.name,
        }
      })

      await Promise.all(uploadList).then((response) =>
        setUploadData(uploadData ? [...uploadData, ...response] : response),
      )
    } catch (err) {
      if (err instanceof Error) {
        setUploadError(err)
      }
    } finally {
      setIsLoadingUpload(false)
    }
  }

  const onSubmit = async (
    values: Omit<AddAdvReviewInput, 'productUid' | 'tmpImagesPath'>,
  ) => {
    if (!amReviewSetting) {
      return
    }

    const { ratings, ...formValues } = values
    const ratingValue = getAmastyReviewRatings(
      amReviewSetting,
      ratings,
      ratingStarCount,
    )

    let tmpImagesPath: string[] = []

    try {
      setSubmitError(undefined)
      setIsLoadingSubmit(true)

      /** Upload review images to temp upload folder */
      if (uploadData) {
        await Promise.all(
          uploadData.map(async (imageObject) => {
            const uploadResponse = await fetch('/api/upload-review-images', {
              method: 'POST',
              body: JSON.stringify({
                reviewImageData: imageObject.reviewImageData,
                reviewImageName: imageObject.reviewImageName,
              }),
            })

            const { uploadedFileName } = await uploadResponse.json()

            /** Add uploaded file name from response to tmpImagesPath, to submit array of image names */
            tmpImagesPath = [...tmpImagesPath, uploadedFileName]
          }),
        )
      }

      submitReview({
        productUid: productUid,
        ratings: JSON.stringify(ratingValue),
        tmpImagesPath: tmpImagesPath,
        ...formValues,
      })
    } catch (error) {
      if (error instanceof Error) {
        setSubmitError(error)
      }
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    const willExceedMaxUploadBodySize =
      maxBodySizeBytes <=
      getFilesSizeInBytes(imageState?.map((image) => image.size) ?? [])

    if (willExceedMaxUploadBodySize) {
      setUploadError(
        new Error(
          t({
            message: `Try to limit images to a maximum of ${maxBodySizeMb}MB`,
          }),
        ),
      )
    } else {
      setUploadError(undefined)
    }
  }, [imageState, setUploadError])

  useEffect(() => {
    setValue('hasValidUpload', uploadError?.message ? false : true, {
      shouldValidate: true,
    })
  }, [uploadError, setValue])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ValidInputCheckBox
          type="checkbox"
          {...register('hasValidUpload', {
            required: true,
          })}
        />
        <Fieldset>
          <StyledH3>
            <Trans>How many stars are you giving?</Trans>
          </StyledH3>

          {ratingOptions?.map((option) => {
            const optionId = option?.optionId ?? 0

            return (
              <StyledButtonUnstyled
                key={`ratingOption-${optionId}`}
                analyticsContext="create.review.form"
                analyticsName={`rating-option-${optionId}`}
                onPress={() =>
                  ratingsValue === String(optionId)
                    ? setValue('ratings', String(optionId - 1), {
                        shouldValidate: true,
                      })
                    : setValue('ratings', String(optionId), {
                        shouldValidate: true,
                      })
                }
              >
                <StyledStarIcon
                  isActive={optionId <= Number(ratingsValue)}
                  hasError={Boolean(ratingsError)}
                />
              </StyledButtonUnstyled>
            )
          })}

          <StyledInputRange
            control={control}
            min="0"
            max={String(ratingStarCount)}
            step="1"
            defaultValue={0}
            {...register('ratings', {
              required: {
                value: true,
                message: errorMessages.required.ratings,
              },
              validate: (value) =>
                value !== '0' || errorMessages.required.ratings,
            })}
          />
        </Fieldset>

        <Fieldset>
          <StyledH3>
            <Trans>Title of your review</Trans>
          </StyledH3>

          <Input
            control={control}
            label={t({
              message: 'Share your experience in one line',
            })}
            {...register('title', {
              // Show input error when input has no value on submit
              required: {
                value: true,
                message: errorMessages.required.title,
              },
            })}
          />
        </Fieldset>

        <Fieldset>
          <StyledH3>
            <Trans>Describe your experience</Trans>
          </StyledH3>

          <InputTextArea
            control={control}
            label={t({
              message: 'Why do you like or dislike the product?',
            })}
            {...register('detail', {
              // Show input error when input has no value on submit
              required: {
                value: true,
                message: errorMessages.required.detail,
              },
              minLength: {
                value: 4,
                message: errorMessages.validation.detail,
              },
            })}
            minLength={4}
          />

          <FormHelpText
            text={t({
              message:
                'Avoid personal information, other websites and ferocious #@! language use. Minimum 4 characters.',
            })}
          />
        </Fieldset>

        <Fieldset>
          <StyledH3>
            <Trans>Product images</Trans>
          </StyledH3>
          <StyledAlert type="error">{uploadError?.message}</StyledAlert>

          {isLoadingUpload ? (
            <Loader />
          ) : (
            <FormInputImagePreview
              files={imageState}
              onRemove={handleRemove}
              analyticsContext="create.review.form.images"
            />
          )}

          <StyledFormInputImageStyled
            onChange={handleInputImageChange}
            capture={undefined}
            multiple
          />
        </Fieldset>

        <Fieldset>
          <StyledH3>
            <Trans>Email address</Trans>
          </StyledH3>

          <FormFieldsEmail
            register={register}
            control={control}
            label={t({
              message: 'Enter your email address here',
            })}
            registerName="guestEmail"
          />
        </Fieldset>

        <Fieldset>
          <StyledH3>
            <Trans>Name</Trans>
          </StyledH3>

          <Input
            control={control}
            label={t({
              message: 'Enter your name here',
            })}
            {...register('nickname', {
              // Show input error when input has no value on submit
              required: {
                value: true,
                message: errorMessages.required.nickname,
              },
            })}
          />
        </Fieldset>

        <StyledButtonPrimary
          analyticsContext="request.license.plate"
          analyticsName="create.plate"
          colorType="neutral"
          isLoading={isSubmitting || isLoadingSubmit}
          disabled={isSubmitting || isLoadingSubmit}
          type="submit"
        >
          <Trans>Submit review</Trans>
        </StyledButtonPrimary>

        {submitReviewDisclaimer && (
          <DisclaimerTextWrapper>
            <HtmlContent html={submitReviewDisclaimer} />
          </DisclaimerTextWrapper>
        )}
      </form>

      <StyledAlert type="error">{submitError?.message}</StyledAlert>
      <StyledAlert type="error">{error?.message}</StyledAlert>
    </>
  )
}

export default CreateReviewForm

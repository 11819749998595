export default async function getBase64StringFromFile(file: File) {
  if (file) {
    const fileToBase64 = (
      fileInput: File,
    ): Promise<string | ArrayBuffer | null> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(fileInput)
      })

    return await fileToBase64(file).then((base64String) =>
      base64String?.slice((base64String as string).indexOf(',') + 1),
    )
  }

  return Promise.reject('file_not_valid')
}

import { stripMaybes } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import { Plural, Trans } from '@lingui/macro'
import React, { useEffect, useRef, useState } from 'react'

import { DetailedSummary } from '@emico/graphql-schema-types'
import { maxWidth, minWidth } from '@emico/styles'
import { H2, Loader } from '@emico/ui'

import { useAdvReview } from '../lib/useAdvReview'
import { useAmReviewSetting } from '../lib/useAmReviewSetting'
import { ProductFragment } from '../packages/product-fragment'
import theme from '../theme'
import ButtonSecondary from './ButtonSecondary'
import ImageRow from './ImageRow'
import Pagination from './Pagination'
import PaginationButton from './PaginationButton'
import Review from './Review'
import ReviewRatingStarTotals from './ReviewRatingStarTotals'
import ReviewsSortForm from './ReviewsSortForm'
import SideModal from './SideModal'
import StarRating from './StarRating'

const AverageRatingOverview = styled.div`
  display: grid;
  gap: ${theme.spacing.lg};
  margin-bottom: ${theme.spacing.lg};
  padding-bottom: ${theme.spacing.lg};
  border-bottom: ${theme.borders.default};

  @media ${minWidth('md')} {
    grid-template-columns: 1fr 1fr;
    gap: ${theme.spacing['2xl']};
    align-items: start;
    margin-bottom: ${theme.spacing.xl};
    padding-bottom: ${theme.spacing.xl};
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.lg};
`

const StyledButtonSecondary = styled(ButtonSecondary)`
  @media ${minWidth('md')} {
    justify-self: end;
  }
`

const AverageRatingValue = styled.span`
  font-size: 60px;
  line-height: 60px;
  font-weight: ${theme.fontWeights.bold};
  margin-right: ${theme.spacing.md};
`

const StyledH2 = styled(H2)`
  margin-bottom: ${theme.spacing.sm};
`

const StyledImageRow = styled(ImageRow)`
  margin-bottom: ${theme.spacing.xl};
`

const StyledImageRowMobile = styled(StyledImageRow)`
  @media ${minWidth('md')} {
    display: none;
  }
`

const StyledImageRowTabletAndDesktop = styled(StyledImageRow)`
  @media ${maxWidth('sm')} {
    display: none;
  }
`

const ReviewsImagesWrapper = styled.div`
  margin-bottom: ${theme.spacing.xl};
  border-bottom: ${theme.borders.default};
`

const SortAndFilterGrid = styled.div`
  display: grid;
  gap: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    grid-template-columns: 1fr 1fr;
  }
`

const StyledReviewsSortForm = styled(ReviewsSortForm)`
  display: grid;
  gap: ${theme.spacing.xs};
`

const StyledLoader = styled(Loader)`
  height: 30px;
  width: 30px;
  margin-top: ${theme.spacing['3xl']};
`

const ReviewList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ReviewListItem = styled.li`
  border-bottom: ${theme.borders.default};
`

const StyledPagination = styled(Pagination)`
  margin-top: ${theme.spacing.xl};

  @media ${minWidth('md')} {
    margin-top: ${theme.spacing['2xl']};
  }
`

interface Props {
  disableEcommerce: boolean
  show: boolean
  close: () => void
  product: ProductFragment
  ratingAverage: number
  reviewsCount: number
  detailedSummary?: DetailedSummary | null
  allReviewsImages: string[]
  setShowCreateReviewModal: (showCreateReviewModal: boolean) => void
  hasMainProduct?: boolean
}

const ReviewsModal = ({
  disableEcommerce,
  show,
  close,
  product,
  ratingAverage,
  reviewsCount,
  detailedSummary,
  allReviewsImages,
  setShowCreateReviewModal,
  hasMainProduct,
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [currentPageIndex, setCurrentPageIndex] = useState(1)
  const [sortOrder, setSortOrder] = useState<string | undefined>(undefined)
  const { data: amReviewSetting } = useAmReviewSetting()
  const { data: reviewsData, loading: isLoadingReviewsData } = useAdvReview(
    product.uid,
    currentPageIndex,
    sortOrder,
  )

  const reviewsPageCount = Math.ceil(reviewsCount > 10 ? reviewsCount / 10 : 1)
  const reviewSortOptions = amReviewSetting?.availableOrders ?? []
  const reviews = reviewsData?.items ?? []

  const isMainProduct =
    product?.isMainProduct || product?.__typename === 'ConfigurableProduct'

  useEffect(() => {
    if (!scrollRef?.current) {
      return
    }

    scrollRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [currentPageIndex])

  return (
    <SideModal
      disableEcommerce={disableEcommerce}
      title={<Trans>Reviews</Trans>}
      show={show}
      close={close}
      product={product}
      showCartButton
      showProductPrice={!isMainProduct}
      scrollRef={scrollRef}
    >
      <AverageRatingOverview>
        <div>
          <Row>
            <AverageRatingValue>{ratingAverage}</AverageRatingValue>

            <div>
              <StarRating value={ratingAverage} />

              <Plural
                value={reviewsCount}
                one="Based on # review"
                other="Based on # reviews"
              />
            </div>
          </Row>

          {detailedSummary && (
            <>
              <StyledH2>
                <Trans>Rating</Trans>
              </StyledH2>

              <ReviewRatingStarTotals
                starsTotals={detailedSummary}
                reviewsCount={reviewsCount}
              />
            </>
          )}
        </div>

        <StyledButtonSecondary
          analyticsContext="reviews.modal"
          analyticsName="write.review"
          colorTheme="dark"
          onClick={() => {
            close()
            setShowCreateReviewModal(true)
          }}
        >
          <Trans>Write a review</Trans>
        </StyledButtonSecondary>
      </AverageRatingOverview>

      {allReviewsImages?.length !== 0 && (
        <ReviewsImagesWrapper>
          <StyledH2>
            <Trans>Customer images</Trans>
          </StyledH2>

          <StyledImageRowMobile
            imageUrls={allReviewsImages}
            visibleImageCount={4}
          />

          <StyledImageRowTabletAndDesktop
            imageUrls={allReviewsImages}
            visibleImageCount={7}
          />
        </ReviewsImagesWrapper>
      )}

      {reviewSortOptions?.length !== 0 && (
        <SortAndFilterGrid>
          <StyledReviewsSortForm
            sortOptions={reviewSortOptions.filter(stripMaybes)}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
          />
        </SortAndFilterGrid>
      )}

      {isLoadingReviewsData ? (
        <StyledLoader />
      ) : (
        reviews.length !== 0 && (
          <ReviewList>
            {reviews.map((review) =>
              review ? (
                <ReviewListItem key={review.reviewId}>
                  <Review review={review} hasMainProduct={hasMainProduct} />
                </ReviewListItem>
              ) : null,
            )}
          </ReviewList>
        )
      )}

      {reviewsPageCount > 1 && (
        <StyledPagination
          current={currentPageIndex}
          total={reviewsPageCount}
          renderElement={({ current, title, isDivider, key }) => (
            <PaginationButton
              key={key}
              analyticsContext="reviews.pagination"
              analyticsName={`to.${current}`}
              onPress={() => setCurrentPageIndex(current)}
              isDivider={isDivider}
              isActive={current === currentPageIndex}
            >
              {title}
            </PaginationButton>
          )}
        />
      )}
    </SideModal>
  )
}

export default ReviewsModal

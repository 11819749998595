import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React from 'react'

import { minWidth } from '@emico/styles'
import { H1 } from '@emico/ui'

import { CategoryPageStaticData } from '../pages/category/useCategory'
import theme from '../theme'
import Breadcrumbs from './Breadcrumbs'
import Container from './Container'
import CraftPageBlocks from './CraftPageBlocks'

const Header = styled.header`
  margin: ${theme.spacing.lg} 0;

  @media ${minWidth('lg')} {
    margin: ${theme.spacing['2xl']} 0;
  }
`

const StyledH1 = styled(H1)`
  font-size: ${theme.fontSizes.xl};
  margin-bottom: ${theme.spacing.sm};

  @media ${minWidth('md')} {
    font-size: ${theme.fontSizes['2xl']};
  }
`

const PageBlocksWrapper = styled.div`
  margin-top: ${theme.spacing['2xl']};
`

interface Props
  extends Pick<CategoryPageStaticData, 'craftDataCategoryGeneric'> {
  categoryName: string
}

const CategoryNoResults = ({
  categoryName,
  craftDataCategoryGeneric,
  ...other
}: Props) => {
  const noResultsContent =
    craftDataCategoryGeneric?.__typename === 'CraftCategoryCategoryEntry'
      ? craftDataCategoryGeneric
      : undefined

  return (
    <section {...other}>
      <Container>
        <Breadcrumbs />

        <Header>
          <StyledH1>
            <Trans>Sorry, no results found for {categoryName}</Trans>
          </StyledH1>
        </Header>
      </Container>

      {noResultsContent?.pageBlocks &&
        noResultsContent.pageBlocks.length !== 0 && (
          <PageBlocksWrapper>
            <CraftPageBlocks blocks={noResultsContent.pageBlocks} />
          </PageBlocksWrapper>
        )}
    </section>
  )
}

export default CategoryNoResults

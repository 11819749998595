import styled from '@emotion/styled'
import React, { forwardRef, InputHTMLAttributes, Ref } from 'react'

import { maxWidth } from '@emico/styles'

export const InputTextArea = styled.textarea`
  // Font-size on mobile devices should at least be 16px. This makes
  // it so that you are at least forces to think about it.
  @media ${maxWidth('sm')} {
    font-size: 16px;
  }
`

/**
 * Text area component
 */

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  rows?: number
}

const TextArea = (props: Props, ref: Ref<HTMLTextAreaElement>) => (
  <InputTextArea {...props} ref={ref} />
)

export default forwardRef(TextArea)

import styled from '@emotion/styled'
import { Trans, t } from '@lingui/macro'
import React, { useEffect, useState } from 'react'

import { H2 } from '@emico/ui'

import { ProductFragment } from '../packages/product-fragment'
import { ReviewQuery } from '../pages/product/useProduct.generated'
import theme from '../theme'
import CreateReviewForm from './CreateReviewForm'
import HtmlContent from './HtmlContent'
import SideModal from './SideModal'

const StyledH2 = styled(H2)`
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.normal};
  margin-bottom: ${theme.spacing.xs};
`

const Subtitle = styled.p`
  font-size: ${theme.fontSizes['2xl']};
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: ${theme.spacing.lg};
  text-transform: uppercase;
`

interface Props {
  disableEcommerce: boolean
  show: boolean
  close: () => void
  product: ProductFragment
  reviewContentData: Exclude<ReviewQuery['entry'], null> | null
  submitReviewDisclaimer?: string | null
}

const CreateReviewModal = ({
  disableEcommerce,
  show,
  close,
  product,
  reviewContentData,
  submitReviewDisclaimer,
}: Props) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const reviewContent =
    reviewContentData?.__typename === 'CraftReviewEntry'
      ? reviewContentData
      : undefined

  // Reset isSuccess state on opening modal
  useEffect(() => {
    if (show) {
      setIsSuccess(false)
    }
  }, [show])

  return (
    <SideModal
      disableEcommerce={disableEcommerce}
      title={<Trans>Write a review</Trans>}
      show={show}
      close={close}
      product={product}
      productPrependText={t({
        message: 'Write your review for:',
      })}
      showProductPrice={false}
      showCartButton
    >
      {isSuccess ? (
        <>
          <StyledH2>{reviewContent?.title}</StyledH2>

          {reviewContent?.subtitle && (
            <Subtitle>{reviewContent.subtitle}</Subtitle>
          )}

          {reviewContent?.richTextSuccess && (
            <div>
              <HtmlContent html={reviewContent.richTextSuccess} />
            </div>
          )}
        </>
      ) : (
        <CreateReviewForm
          productUid={product.uid}
          onSuccess={() => setIsSuccess(true)}
          submitReviewDisclaimer={submitReviewDisclaimer}
        />
      )}
    </SideModal>
  )
}

export default CreateReviewModal

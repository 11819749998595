import { Image } from '@emico-react/image'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React, { ReactNode, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'

import { ProductLabelListFragment } from '@emico/product-label-fragment'
import { ButtonUnstyled } from '@emico/ui'
import { sortArrayByProperty } from '@emico/utils'

import ChevronDownIcon from '../icons/ChevronDownIcon'
import { GalleryItem, ValidAmbianceImage } from '../lib/customTypes'
import getYoutubeIdFromUrl from '../lib/getYoutubeIdFromUrl'
import { opacityStates } from '../lib/transitionStates'
import theme from '../theme'
import ButtonPrimary from './ButtonPrimary'
import ProductLabels, { GridLayoutTypeEnum } from './ProductLabels'
import ProductPageMediaModal from './ProductPageMediaModal'
import VideoWithPlaceholder from './VideoWithPlaceholder'

const Media = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .ReactCollapse--collapse {
    transition-property: ${theme.transition.properties.dimensions};
    transition-duration: ${theme.transition.durations.extraSlow};
    transition-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
  }
`

const MediaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${theme.spacing.xxs};
  width: 100%;
`

const MediaItemWrapper = styled('figure', {
  shouldForwardProp: (prop) =>
    !['isFullWidth', 'hasBackgroundColor'].includes(prop.toString()),
})<{ isFullWidth?: boolean; hasBackgroundColor?: boolean }>`
  position: relative;
  grid-column: ${({ isFullWidth }) => isFullWidth && '1 / span 2'};
  background-color: ${({ hasBackgroundColor }) =>
    hasBackgroundColor && theme.colors.backgroundLight};
  margin: 0;
`

const GalleryImage = styled(Image, {
  shouldForwardProp: (prop) =>
    !['isSquare', 'isProductImage'].includes(prop.toString()),
})<{ isSquare?: boolean; isProductImage?: boolean }>`
  width: 100%;
  height: auto;

  ${({ isSquare, isProductImage }) =>
    isSquare
      ? css`
          aspect-ratio: 1 / 1;
          object-fit: ${isProductImage ? 'contain' : 'cover'};
        `
      : css`
          aspect-ratio: 16 / 9;
          object-fit: ${isProductImage ? 'contain' : 'cover'};
        `}
`

const StyledProductLabels = styled(ProductLabels)`
  padding: ${theme.spacing.lg} ${theme.containerPadding} ${theme.spacing.sm};
`

const StyledButtonUnstyled = styled(ButtonUnstyled)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
`

const StyledVideoWithPlaceholder = styled(VideoWithPlaceholder)`
  grid-column: 1 / span 2;
`

const LoadMoreItems = styled(MediaGrid, {
  shouldForwardProp: (prop) => !['show'].includes(prop.toString()),
})<{ show: boolean }>`
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.extraSlow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  opacity: ${({ show }) => (show ? 1 : 0)};
  margin-top: ${theme.spacing.xxs};
`

const ButtonContainer = styled('div', {
  shouldForwardProp: (prop) => !['state'].includes(prop.toString()),
})<{ state: TransitionStatus }>`
  position: absolute;
  bottom: 0;
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.slow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};

  opacity: ${({ state }) => opacityStates[state]};
  transform: translateY(50%);
  z-index: 1;
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  font-weight: ${theme.fontWeights.medium};
`

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  margin-left: ${theme.spacing.xs};
`

interface MediaItemProps {
  children: ReactNode
  toggleMediaModal: () => void
  isFullWidth?: boolean
  hasBackgroundColor?: boolean
}

const MediaItem = ({
  children,
  toggleMediaModal,
  isFullWidth,
  hasBackgroundColor,
}: MediaItemProps) => (
  <MediaItemWrapper
    isFullWidth={isFullWidth}
    hasBackgroundColor={hasBackgroundColor}
  >
    {children}

    <StyledButtonUnstyled
      onClick={toggleMediaModal}
      analyticsContext="product.page.media"
      analyticsName="open.media.modal"
    >
      <Trans>Zoom</Trans>
    </StyledButtonUnstyled>
  </MediaItemWrapper>
)

interface MediaItems {
  galleryItems: GalleryItem[]
  ambianceItems: ValidAmbianceImage[]
}

interface Props {
  mediaItems: MediaItems
  productLabels: ProductLabelListFragment[] | undefined
}

const ProductPageMedia = ({ mediaItems, productLabels, ...other }: Props) => {
  const [showMoreMediaItems, setShowMoreMediaItems] = useState<boolean>(false)
  const [mediaModalIsOpen, setMediaModalIsOpen] = useState<boolean>(false)
  const [currentImage, setCurrentImage] = useState<
    GalleryItem | ValidAmbianceImage | undefined
  >(undefined)

  const toggleMediaModal = (
    currentImage?: GalleryItem | ValidAmbianceImage,
  ) => {
    setCurrentImage(currentImage)
    setMediaModalIsOpen((mediaModalIsOpen) => !mediaModalIsOpen)
  }

  const galleryItems = mediaItems.galleryItems
  const ambianceImages = mediaItems.ambianceItems
  const galleryImages = galleryItems.filter(
    (item) => item.__typename === 'ProductImage',
  )

  const galleryVideos = galleryItems.filter(
    (item) => item.__typename === 'ProductVideo',
  )

  const firstGalleryImages = galleryImages.slice(0, 3)
  const otherGalleryImages = galleryImages.slice(3)
  const firstGalleryVideo = galleryVideos.slice(0, 1)[0]

  const firstAmbianceImages = ambianceImages.slice(0, 2)
  const otherMediaItems = [
    ...otherGalleryImages,
    ...(firstGalleryImages.length === 1
      ? ambianceImages.slice(2)
      : ambianceImages),
    ...galleryVideos.slice(1),
  ]

  const sortedOtherMediaItems = sortArrayByProperty(
    otherMediaItems,
    (mediaItem) =>
      mediaItem.position || mediaItem.position === 0
        ? mediaItem.position
        : null,
  )

  return (
    <Media {...other}>
      {productLabels && productLabels.length !== 0 && (
        <StyledProductLabels
          productLabels={productLabels}
          gridLayoutType={GridLayoutTypeEnum.COLUMN}
        />
      )}

      <MediaGrid>
        {firstGalleryImages.map((galleryImage, index) => {
          if (!galleryImage.url) {
            return null
          }

          const isFullWidth = index === 0 || firstGalleryImages.length < 3

          return (
            <MediaItem
              key={galleryImage.url}
              isFullWidth={isFullWidth}
              hasBackgroundColor
              toggleMediaModal={() => toggleMediaModal(galleryImage)}
            >
              <GalleryImage
                url={galleryImage.url}
                alt={galleryImage.label ?? ''}
                lazy={index > 2}
                sizes={
                  isFullWidth
                    ? theme.imageSizes.productGalleryImage.sizes
                    : theme.imageSizes.square.sizes
                }
                isSquare={!isFullWidth}
                isProductImage
                width={isFullWidth ? undefined : 600}
                height={isFullWidth ? undefined : 600}
              />
            </MediaItem>
          )
        })}

        {firstGalleryImages.length === 1 &&
          firstAmbianceImages.map((ambianceImage) => {
            if (!ambianceImage.url) {
              return null
            }

            const isFullWidth = firstAmbianceImages.length === 1

            return (
              <MediaItem
                key={ambianceImage.url}
                toggleMediaModal={() => toggleMediaModal(ambianceImage)}
                isFullWidth={isFullWidth}
              >
                <GalleryImage
                  url={ambianceImage.url}
                  alt={ambianceImage.label ?? ''}
                  lazy={false}
                  sizes={
                    isFullWidth
                      ? theme.imageSizes.productGalleryImage.sizes
                      : theme.imageSizes.square.sizes
                  }
                  isSquare={!isFullWidth}
                  width={isFullWidth ? undefined : 600}
                  height={isFullWidth ? undefined : 600}
                />
              </MediaItem>
            )
          })}

        {firstGalleryVideo?.__typename === 'ProductVideo' &&
          firstGalleryVideo.videoContent?.videoUrl && (
            <StyledVideoWithPlaceholder
              videoId={getYoutubeIdFromUrl(
                firstGalleryVideo.videoContent.videoUrl ?? '',
              )}
              placeholderImage={firstGalleryVideo.url}
            />
          )}
      </MediaGrid>

      <Collapse isOpened={showMoreMediaItems}>
        <LoadMoreItems show={showMoreMediaItems}>
          {sortedOtherMediaItems.map((mediaItem, index) => {
            const isVideo = mediaItem.__typename === 'ProductVideo'
            const videoId =
              isVideo &&
              getYoutubeIdFromUrl(mediaItem.videoContent?.videoUrl ?? '')
            const isProductImage = mediaItem.__typename === 'ProductImage'

            const imageCount = sortedOtherMediaItems.length
            const isCountEven = imageCount % 2 === 0
            const isLastItem = index === imageCount - 1
            const isFullWidth = isLastItem && !isCountEven

            return videoId
              ? mediaItem.videoContent?.videoUrl && (
                  <StyledVideoWithPlaceholder
                    key={mediaItem.videoContent.videoUrl}
                    videoId={videoId}
                    placeholderImage={mediaItem.url}
                  />
                )
              : mediaItem.url && (
                  <MediaItem
                    key={mediaItem.url}
                    toggleMediaModal={() => toggleMediaModal(mediaItem)}
                    hasBackgroundColor={isProductImage}
                    isFullWidth={isFullWidth}
                  >
                    <GalleryImage
                      url={mediaItem.url}
                      alt={mediaItem.label ?? ''}
                      lazy
                      sizes={
                        isFullWidth
                          ? theme.imageSizes.productGalleryImage.sizes
                          : theme.imageSizes.square.sizes
                      }
                      isSquare={!isFullWidth}
                      width={600}
                      height={600}
                      isProductImage={isProductImage}
                    />
                  </MediaItem>
                )
          })}
        </LoadMoreItems>
      </Collapse>

      {sortedOtherMediaItems.length !== 0 && (
        <Transition in={!showMoreMediaItems} timeout={800}>
          {(state: TransitionStatus) => (
            <ButtonContainer state={state}>
              <StyledButtonPrimary
                analyticsContext="product.page.media"
                analyticsName="show.more"
                onClick={() => setShowMoreMediaItems(true)}
                colorType="neutral"
                withIcon
              >
                <Trans>Show more</Trans>

                <StyledChevronDownIcon />
              </StyledButtonPrimary>
            </ButtonContainer>
          )}
        </Transition>
      )}

      <ProductPageMediaModal
        show={mediaModalIsOpen}
        ambianceImages={ambianceImages}
        galleryImages={galleryImages}
        currentImage={currentImage}
        close={() => toggleMediaModal(undefined)}
      />
    </Media>
  )
}

export default ProductPageMedia

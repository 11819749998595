import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { Fragment } from 'react'

import { ProductDownload } from '@emico/graphql-schema-types'
import { CheckmarkRoundIcon, CrossRoundIcon } from '@emico/icons'
import { minWidth } from '@emico/styles'

import { ProductFragment } from '../packages/product-fragment'
import theme from '../theme'
import NextLinkPrimary from './NextLinkPrimary'

const List = styled.dl`
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0;

  @media ${minWidth('md')} {
    grid-template-columns: 40% 60%;
  }
`

const attributeStyling = css`
  padding: ${theme.spacing.sm};
  font-size: ${theme.fontSizes.sm};

  &:nth-of-type(odd) {
    background-color: ${theme.colors.backgroundLight};
  }
`

const Label = styled.dt`
  ${attributeStyling};
`

const Value = styled.dd`
  ${attributeStyling};
  margin: 0;
`

const StyledCheckmarkRoundIcon = styled(CheckmarkRoundIcon)`
  color: ${theme.colors.success};
  font-size: 15px;
`

const StyledCrossRoundIcon = styled(CrossRoundIcon)`
  color: ${theme.colors.grayBrown};
  font-size: 15px;
`

const getAttributeValue = (value: string) => {
  switch (value) {
    case 'true':
      return <StyledCheckmarkRoundIcon />
    case 'false':
      return <StyledCrossRoundIcon />
    default:
      return value
  }
}

interface Props {
  attributes?:
    | Exclude<ProductFragment['dynamicAttributes'], null>
    | Exclude<ProductFragment['highlightedAttributes'], null>
    | ProductDownload['downloads']
}

const AttributesTable = ({ attributes, ...other }: Props) => {
  if (!attributes || attributes.length === 0) {
    return null
  }

  return (
    <List {...other}>
      {attributes?.map((attribute, index) => {
        switch (attribute?.__typename) {
          case 'HighlightAttribute':
          case 'DynamicAttribute':
            return (
              <Fragment key={attribute.attributeLabel ?? index}>
                <Label>{attribute.attributeLabel}</Label>

                <Value>
                  {attribute.attributeValue &&
                    getAttributeValue(attribute.attributeValue)}
                </Value>
              </Fragment>
            )
          case 'Downloads':
            if (!attribute.url) {
              return null
            }

            return (
              <Fragment key={attribute.filename ?? index}>
                <Label>{attribute.filename}</Label>

                <Value>
                  <NextLinkPrimary
                    analyticsContext="attributes.table"
                    analyticsName={`download.${attribute.filename}`}
                    href={attribute.url}
                    target="_blank"
                    isBasic
                  >
                    {attribute.buttonText}
                  </NextLinkPrimary>
                </Value>
              </Fragment>
            )
          default:
            return null
        }
      })}
    </List>
  )
}

export default AttributesTable

import { gql, TypedDocumentNode, useQuery } from '@apollo/client'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'

import {
  ConfigurableProductOption,
  Maybe,
  ProductStockStatus,
  SwatchDataInterface,
} from '@emico/graphql-schema-types'

import { productFragment, ProductFragment } from '../packages/product-fragment'
import getIsPreorder from './getIsPreorder'
import { getProductVariantsBasedOnSelectedOptions } from './getProductVariantsBasedOnSelectedOptions'
import {
  SimpleConfiguredProductQuery,
  SimpleConfiguredProductQueryVariables,
} from './useProductConfigurables.generated'

export interface ProductConfigurableOptionValue {
  key: string
  label: string
  attributeUid: string
  isPreorder: boolean
  swatch?: Maybe<SwatchDataInterface>
  disabled?: boolean
}

export interface ProductConfigurableOptions {
  position?: Maybe<number>
  label?: Maybe<string>
  key: string
  code: string
  values?: ProductConfigurableOptionValue[]
}

interface ProductConfigurableInfo {
  configuredSimple?: Maybe<ProductFragment>
  configurableOptions?: ProductConfigurableOptions[]
  isFetching: boolean
}

interface PreOrder {
  atpDate: string
  atpDateFormatted: string
  isPreorder: boolean
}

const simpleQuery = gql`
  query SimpleConfiguredProduct($uid: ID!, $optionValueUids: [ID!]!) {
    productByUid(uid: $uid) {
      uid

      ... on ConfigurableProduct {
        variants {
          attributes {
            code
            label
            valueIndex
            uid
          }
          product {
            uid
            urlKey
            onlyXLeftInStock
            stockStatus

            preorder {
              atpDate
              atpDateFormatted
              isPreorder
            }

            image {
              url
            }
          }
        }

        configurableProductOptionsSelection(
          configurableOptionValueUids: $optionValueUids
        ) {
          initialConfigurableOptions {
            values {
              isAvailable
              isUseDefault
              label
              swatch {
                ... on ColorSwatchData {
                  value
                }
                ... on ImageSwatchData {
                  thumbnail
                  value
                }
                ... on TextSwatchData {
                  value
                }
              }
              uid
            }
            uid
            label
            attributeCode
          }

          configurableOptions {
            attributeCode
            label
            uid

            values {
              uid
              isAvailable
              isUseDefault
              label

              swatch {
                value
              }
            }
          }

          mediaGallery {
            disabled
            label
            position
            url
          }

          optionsAvailableForSelection {
            attributeCode
            optionValueUids
          }

          variant {
            ...ProductFragment
          }
        }
      }
    }
  }
  ${productFragment}
` as TypedDocumentNode<
  SimpleConfiguredProductQuery,
  SimpleConfiguredProductQueryVariables
>

export default function useProductConfigurables(
  product: Maybe<ProductFragment>,
  optionValueUids?: string[],
): ProductConfigurableInfo {
  const { push } = useRouter()
  const [configurableOptions, setConfigurableOptions] =
    useState<ProductConfigurableOptions[]>()
  const filtersRef = useRef<Record<string, ProductConfigurableOptions>>()

  // Simple product based on the current selection of configurable options (optionValueUids)
  const { data: simpleProduct, networkStatus } = useQuery(simpleQuery, {
    variables: {
      optionValueUids: optionValueUids ?? [],
      uid: product?.uid ?? '',
    },
  })
  const isFetching = networkStatus ? networkStatus < 7 : false

  const productByUidWithOptionsSelection =
    simpleProduct?.productByUid &&
    'configurableProductOptionsSelection' in simpleProduct.productByUid
      ? simpleProduct.productByUid
      : undefined

  const productByUidWithVariants =
    simpleProduct?.productByUid && 'variants' in simpleProduct.productByUid
      ? simpleProduct.productByUid
      : undefined

  const configuredSimple =
    productByUidWithOptionsSelection?.configurableProductOptionsSelection
      ?.variant ?? undefined

  const configurableProductOptions =
    productByUidWithOptionsSelection?.configurableProductOptionsSelection
      ?.configurableOptions

  const productVariants = productByUidWithVariants?.variants

  const optionIsAvailable = (
    uid: string,
    attributeCode: string,
    isAvailable?: boolean,
  ) => {
    if (attributeCode === 'color_visual' && isAvailable) {
      return false
    }

    const productVariantsInStock = productVariants?.filter(
      (productVariant) =>
        productVariant?.product?.stockStatus === ProductStockStatus.IN_STOCK,
    )

    const availableProductVariants = productVariantsInStock
      ?.map((productVariant) => {
        const everyOptionValueUidIsIncludedInProductVariant =
          optionValueUids?.every((optionValueUid) =>
            productVariant?.attributes?.find(
              (attribute) => attribute?.uid === optionValueUid,
            ),
          )

        if (
          !optionValueUids ||
          optionValueUids?.length === 0 ||
          everyOptionValueUidIsIncludedInProductVariant
        ) {
          return productVariant
        } else {
          return undefined
        }
      })
      .filter((productVariant) => productVariant)

    return (
      !availableProductVariants?.some((availableProductVariant) =>
        availableProductVariant?.attributes?.some(
          (attribute) => attribute?.uid === uid,
        ),
      ) || false
    )
  }

  const getOptionPreorderStatus = (
    optionValueUid: string,
    selectedConfigurableOptionsExcludingCurrent: string[],
  ) => {
    const availableProductVariantsBasedOnSelectedOptions = optionValueUids
      ? getProductVariantsBasedOnSelectedOptions(
          productVariants?.filter(stripMaybes) ?? [],
          selectedConfigurableOptionsExcludingCurrent,
        )
      : []

    const variantsWithCurrentOptionValue =
      availableProductVariantsBasedOnSelectedOptions.filter((variant) =>
        variant?.attributes?.some(
          (attribute) => attribute?.uid === optionValueUid,
        ),
      )

    const variantResult =
      variantsWithCurrentOptionValue.length === 1
        ? variantsWithCurrentOptionValue[0]
        : undefined

    const variantResultPreorder =
      variantResult?.product && 'preorder' in variantResult.product
        ? (variantResult.product.preorder as PreOrder)
        : undefined

    return variantResultPreorder ? getIsPreorder(variantResultPreorder) : false
  }

  const getInitialConfigurableValueByUid = (uid?: string | null) =>
    productByUidWithOptionsSelection?.configurableProductOptionsSelection?.initialConfigurableOptions
      ?.map((attribute) =>
        attribute?.values?.find((value) => value?.uid === uid),
      )
      .filter((value) => value)[0]

  const renderConfigurableOptions = (
    options: ConfigurableProductOption[],
  ): Record<string, ProductConfigurableOptions> => {
    // get position from the static product, because position is not available in configurableProductOption.
    const getOptionPosition = (option: ConfigurableProductOption) => {
      if (product && 'configurableOptions' in product) {
        return product.configurableOptions?.find(
          (opt) => opt?.attributeCode === option.attributeCode,
        )?.position
      }

      return undefined
    }

    const makeRenderableOption = (
      option: ConfigurableProductOption,
    ): ProductConfigurableOptions => ({
      position: getOptionPosition(option),
      label: option?.label,
      code: option.attributeCode ?? '',
      key: option?.attributeCode ?? '',
      values: option?.values?.filter(stripMaybes).map((value) => {
        const initialConfigurableValueByUid = getInitialConfigurableValueByUid(
          value?.uid,
        )

        const selectedOptionValue = option.values?.find((optionValue) =>
          optionValue?.uid
            ? optionValueUids?.includes(optionValue?.uid)
            : optionValue,
        )?.uid

        const selectedConfigurableOptionsExcludingCurrent =
          optionValueUids?.filter(
            (selectedOption) => selectedOption !== selectedOptionValue,
          )

        const isValueSelected = optionValueUids?.includes(value.uid)

        const isOptionPreorder =
          value?.uid && selectedConfigurableOptionsExcludingCurrent
            ? getOptionPreorderStatus(
                value.uid,
                selectedConfigurableOptionsExcludingCurrent,
              )
            : false

        return {
          key: value?.uid ?? '',
          label: value?.label ?? '',
          attributeUid: option?.attributeCode ?? '',
          isPreorder: !isValueSelected && isOptionPreorder,
          swatch: initialConfigurableValueByUid?.swatch,
          disabled:
            value?.uid && option.attributeCode
              ? optionIsAvailable(
                  value.uid,
                  option.attributeCode,
                  initialConfigurableValueByUid?.isAvailable,
                )
              : false,
        }
      }),
    })

    return options?.reduce(
      (memo: Record<string, ProductConfigurableOptions>, curr) => ({
        ...memo,
        [`${curr.attributeCode}`]: makeRenderableOption(curr),
      }),
      {},
    )
  }

  useEffect(() => {
    if (isFetching) {
      return
    }

    if (
      !product ||
      !('configurableOptions' in product) ||
      !configurableProductOptions
    ) {
      setConfigurableOptions(undefined)

      return
    }
    if (!filtersRef.current) {
      // initial?
      filtersRef.current = renderConfigurableOptions(
        configurableProductOptions as ConfigurableProductOption[],
      )
    } else {
      const previousCodes = Object.keys(filtersRef.current)
      const configCodes = configurableProductOptions.map(
        (confOpt) => confOpt?.attributeCode,
      )

      const previousConfigCodes = previousCodes.filter(
        (prevCode) => !configCodes.includes(prevCode),
      )

      const prevConfig = previousConfigCodes.reduce(
        (memo, code) => ({ ...memo, [code]: filtersRef.current?.[code] }),
        {},
      )

      filtersRef.current = {
        ...renderConfigurableOptions(
          configurableProductOptions as ConfigurableProductOption[],
        ),
        ...prevConfig,
      }
    }

    const configOptions = Object.values(filtersRef.current)

    setConfigurableOptions(configOptions)
  }, [configurableProductOptions, product])

  useEffect(() => {
    if (configuredSimple?.urlKey) {
      push(configuredSimple.urlKey)
    }
  }, [configuredSimple?.urlKey, push])

  return {
    configurableOptions,
    configuredSimple,
    isFetching, // @apollo/client/core/networkStatus/isNetworkRequestInFlight.ts,
  }
}

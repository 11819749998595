import styled from '@emotion/styled'
import React from 'react'

import theme from '../theme'

const HelpText = styled.p`
  color: ${theme.colors.grayBrown};
  margin: ${theme.spacing.xs} 0 0;
`

interface Props {
  text: string
}

const FormHelpText = ({ text }: Props) => <HelpText>{text}</HelpText>

export default FormHelpText

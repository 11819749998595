import { stripMaybes } from '@emico-utils/graphql-data-utils'

import { AmReviewSetting } from './useAmReviewSetting'

interface RatingInput {
  option_id: string
  rating_id: string
}

/**
 * Function to get RatingInput object
 *
 * @param settings Amasty review settings, based on useAmReviewSettings
 * @param ratingsValue Selected form value for review rating
 * @param maxRatingValue Max possible review rating valuel
 * @returns RatingInput object
 */
export default function getAmastyReviewRatings(
  settings: AmReviewSetting,
  ratingsValue: string,
  maxRatingValue?: number,
): RatingInput[] | undefined {
  return settings.ratings
    ?.filter(stripMaybes)
    .map((rating) => {
      const multiplier = maxRatingValue
        ? (rating.ratingOptions?.length ?? 0) / maxRatingValue
        : 1

      const option = rating.ratingOptions
        ?.filter(stripMaybes)
        .find(
          (ratingOption) =>
            String(ratingOption.value) ===
            String(Number(ratingsValue) * multiplier),
        )

      return {
        option_id: String(option?.optionId),
        rating_id: String(rating?.ratingId),
      }
    })
    .filter(stripMaybes)
}

import {
  useQuery,
  gql,
  TypedDocumentNode,
  QueryHookOptions,
} from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import {
  AmReviewSettingQuery,
  AmReviewSettingQueryVariables,
} from './useAmReviewSetting.generated'

export const amReviewSettingQuery = gql`
  query AmReviewSettingQuery {
    amReviewSetting {
      availableOrders {
        code
        label
      }

      ratings {
        ratingCode
        ratingId

        ratingOptions {
          optionId
          ratingCode
          ratingId
          value

          options {
            code
            optionId
            position
            value
          }
        }
      }
    }
  }
` as TypedDocumentNode<AmReviewSettingQuery, AmReviewSettingQueryVariables>

export type AmReviewSetting = Exclude<
  AmReviewSettingQuery['amReviewSetting'],
  null
>

/**
 * Query to get Amasty review settings
 *
 * @returns amReviewSetting object to access Amasty review settings
 *
 * Project usage example:
 *
 * ```
 * const Component = () => {
 *  const { data: amReviewSetting } = useAmReviewSetting(product.uid)
 *
 *  [...]
 * }
 * ```
 */

export function useAmReviewSetting(
  options?: QueryHookOptions<
    AmReviewSettingQuery,
    AmReviewSettingQueryVariables
  >,
) {
  const { data, error, loading, ...others } = useQuery(amReviewSettingQuery, {
    context: getCacheableContext(),
    ...options,
  })

  return {
    ...others,
    loading,
    data: data?.amReviewSetting ?? undefined,
    error,
  }
}

import { ProductFragment } from '@emico-hooks/product-fragment'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React from 'react'

import { minWidth } from '@emico/styles'

import theme from '../theme'
import ColorVariant from './ColorVariant'

const CurrentModelText = styled.div`
  margin-bottom: ${theme.spacing.sm};
`

const Highlighted = styled.span`
  font-weight: ${theme.fontWeights.medium};
`

const ColorVariantLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${theme.spacing.sm};
  margin-bottom: ${theme.spacing['2xl']};

  @media ${minWidth('md')} {
    grid-template-columns: repeat(6, 1fr);
  }

  @media ${minWidth('lg')} {
    grid-template-columns: repeat(4, 1fr);
  }
`

interface ColorVariantProps {
  uid: string
  name: string | undefined | null
  modelName: string | undefined | null
  url: string | undefined | null
  image: string | undefined | null
  status: number | undefined | null
  isSalable: boolean
  isCurrent: boolean
  isMainProduct: boolean
}

interface Props {
  product: ProductFragment
  colorVariants: ColorVariantProps[]
}

const ColorVariants = ({ colorVariants }: Props) => {
  const currentVariant = colorVariants.find((variant) => variant.isCurrent)

  return (
    <div>
      <CurrentModelText>
        <Trans>
          <Highlighted>Model:</Highlighted>{' '}
          {currentVariant && currentVariant?.modelName}
        </Trans>
      </CurrentModelText>

      <ColorVariantLinks>
        {colorVariants.map((variant) => (
          <ColorVariant key={variant.uid} {...variant} />
        ))}
      </ColorVariantLinks>
    </div>
  )
}

export default ColorVariants

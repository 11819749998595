import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'
import { useCallback, useState } from 'react'

import {
  AddAdvReviewMutation,
  AddAdvReviewMutationVariables,
} from './useAddAdvReview.generated'

export interface AddAdvReviewInput {
  productUid: string
  ratings: string
  title: string
  detail: string
  tmpImagesPath?: string[]
  guestEmail: string
  nickname: string
}

const addAdvReviewMutation = gql`
  mutation AddAdvReview($input: AddAdvReviewInput) {
    addAdvReview(input: $input) {
      success
    }
  }
` as TypedDocumentNode<AddAdvReviewMutation, AddAdvReviewMutationVariables>

/**
 * Mutation to create a product review
 *
 * Usage example:
 *
 * ```ts
 * export function exampleFunction() {
 *  const { submitReview, isSuccess, isSubmitting, error } = useAddAdvReview()
 *
 *  const onSubmit = (values: FormValues) => {
 *    submitReview(values)
 *  }

 * }
 * ```
 */

export function useAddAdvReview() {
  const client = useApolloClient()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const submitReview = useCallback(
    /**
     *
     * @param input Create review input values
     */
    async (input: AddAdvReviewInput) => {
      try {
        setIsSubmitting(true)
        setIsSuccess(false)
        setError(null)

        const { data } = await client.mutate({
          mutation: addAdvReviewMutation,
          variables: {
            input: {
              productUid: input.productUid,
              ratings: input.ratings,
              title: input.title,
              detail: input.detail,
              guest_email: input.guestEmail,
              nickname: input.nickname,
              tmp_images_path: input.tmpImagesPath,
            },
          },
        })

        if (data?.addAdvReview?.success) {
          setIsSuccess(true)
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error)
        }
      } finally {
        setIsSubmitting(false)
      }
    },
    [client],
  )

  return {
    submitReview,
    isSubmitting,
    isSuccess,
    error,
  }
}

import styled from '@emotion/styled'
import React from 'react'

import { Maybe } from '@emico/graphql-schema-types'
import { CheckmarkRoundIcon } from '@emico/icons'

import { CraftUspFragment } from '../lib/craftFragments.generated'
import theme from '../theme'
import HtmlContent from './HtmlContent'

const UspList = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
`

const Usp = styled.li`
  display: flex;
  margin-bottom: ${theme.spacing.xs};
  font-size: ${theme.fontSizes.lg};

  p {
    margin: 0 ${theme.spacing.xs} 0 0;

    &:last-child {
      margin: 0;
    }
  }
`

const IconWrapper = styled.div`
  margin-right: ${theme.spacing.sm};
  font-size: ${theme.fontSizes.md};
  width: 16px;
`

const StyledCheckmarkRoundIcon = styled(CheckmarkRoundIcon)`
  color: ${theme.colors.green};
`

interface Props {
  usps: Array<Maybe<CraftUspFragment>>
}

const UspsList = ({ usps, ...other }: Props) => (
  <UspList {...other}>
    {usps.map((usp) => {
      if (!usp?.uspItem) {
        return null
      }

      return (
        <Usp key={usp.id}>
          <IconWrapper>
            {usp.showIcon && <StyledCheckmarkRoundIcon />}
          </IconWrapper>

          <HtmlContent html={usp.uspItem} />
        </Usp>
      )
    })}
  </UspList>
)

export default UspsList

import styled from '@emotion/styled'
import React from 'react'

import { minWidth } from '@emico/styles'

import { CraftProductPageBlocksFragmentCraftImageContentBannerEntry } from '../lib/craftFragments.generated'
import theme from '../theme'
import CraftBlock from './CraftBlock'
import CraftImage from './CraftImage'
import HtmlContent from './HtmlContent'
import SectionHeader from './SectionHeader'

enum ImageAlignmentEnum {
  LEFT = 0,
  RIGHT = 1,
}

enum ImageAlignmentMobileEnum {
  BOTTOM = 0,
  TOP = 1,
}

const StyledCraftBlock = styled(CraftBlock)`
  padding-left: ${theme.containerPadding};
  padding-right: ${theme.containerPadding};
`

const Grid = styled.article`
  display: grid;
  grid-gap: ${theme.spacing.xl};
  align-items: center;

  @media ${minWidth('md')} {
    grid-template-columns: 1fr 1fr;
  }
`

interface StylingProps {
  imageAlignment?: boolean
  imageAlignmentMobile?: boolean
}

const Content = styled('div', {
  shouldForwardProp: (prop) =>
    !['imageAlignment', 'imageAlignmentMobile'].includes(prop.toString()),
})<StylingProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  order: ${({ imageAlignmentMobile }) =>
    imageAlignmentMobile === Boolean(ImageAlignmentMobileEnum.BOTTOM) ? 0 : 1};

  @media ${minWidth('md')} {
    order: ${({ imageAlignment }) =>
      imageAlignment === Boolean(ImageAlignmentEnum.RIGHT) ? 0 : 1};
  }
`

const Text = styled.p`
  margin-bottom: 0;
`

const StyledCraftImage = styled(CraftImage)`
  max-width: 100%;
  height: auto;
`

interface Props {
  block: CraftProductPageBlocksFragmentCraftImageContentBannerEntry
}

const CraftProductImageContentBannerBlock = ({ block, ...other }: Props) => {
  const genericItem = block.genericItem?.[0]
  const image = block.image?.[0]

  if (
    !genericItem ||
    genericItem.__typename !== 'CraftImageContentItemEntry' ||
    !image
  ) {
    return null
  }

  return (
    <StyledCraftBlock {...other}>
      <Grid>
        <Content
          imageAlignment={block.imageAlignment ?? undefined}
          imageAlignmentMobile={block.imageAlignmentMobile ?? undefined}
        >
          <SectionHeader
            title={genericItem.title}
            subtitle={genericItem.subtitle}
            hasLargeTitle
          />
          {genericItem.richText ? (
            <HtmlContent html={genericItem.richText} />
          ) : (
            <Text>{genericItem.text}</Text>
          )}
        </Content>

        <StyledCraftImage
          image={image}
          sizes={{
            xs: 767,
            md: 992,
            lg: 410,
            xl: 410,
          }}
        />
      </Grid>
    </StyledCraftBlock>
  )
}

export default CraftProductImageContentBannerBlock

import { Picture } from '@emico-react/image'
import styled from '@emotion/styled'
import React from 'react'

import { H3 } from '@emico/ui'

import { CraftGenericItemsUspsFragment } from '../lib/craftFragments.generated'
import theme from '../theme'

const Figure = styled.figure`
  position: relative;
  margin: 0 0 ${theme.spacing.md};
  aspect-ratio: 1 / 1;
`

const StyledPicture = styled(Picture)`
  width: 100%;
  height: auto;
`

const Header = styled.header`
  margin-bottom: ${theme.spacing.xs};
`

const Title = styled(H3)`
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`

const Text = styled.p`
  margin: 0;
`

interface Props {
  usp: CraftGenericItemsUspsFragment
}

const CraftProductUsp = ({ usp, ...other }: Props) => {
  const image = usp.image?.[0]

  return (
    <article {...other}>
      <Header>
        {image && (
          <Figure>
            <StyledPicture
              style={{}}
              breakpoints={{
                mobile: {
                  url: image?.mobileUrl ?? '',
                  width: 600,
                  height: 600,
                },
                tablet: {
                  url: image?.tabletUrl ?? '',
                  width: 600,
                  height: 600,
                },
                desktop: {
                  url: image?.desktopUrl ?? '',
                  width: 190,
                  height: 190,
                },
              }}
              alt={image.title ?? ''}
              lazy={false}
            />
          </Figure>
        )}

        <Title>{usp.title}</Title>
      </Header>

      <Text>{usp.text}</Text>
    </article>
  )
}

export default CraftProductUsp

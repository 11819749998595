import { ComponentSlider } from '@emico-react/component-slider'
import { Image } from '@emico-react/image'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React, { useState } from 'react'

import { PlayCircleIcon } from '@emico/icons'
import { ProductLabelListFragment } from '@emico/product-label-fragment'
import { ButtonUnstyled } from '@emico/ui'

import { GalleryItem, ValidAmbianceImage } from '../lib/customTypes'
import getYoutubeIdFromUrl from '../lib/getYoutubeIdFromUrl'
import theme from '../theme'
import ProductLabels from './ProductLabels'
import VideoModal from './VideoModal'
import VideoWithPlaceholder, {
  StyledImage as VideoPlaceholderImage,
} from './VideoWithPlaceholder'

const Media = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const StyledProductLabels = styled(ProductLabels)`
  padding: ${theme.spacing.md} ${theme.containerPadding} ${theme.spacing.sm};
`

const StyledComponentSlider = styled(ComponentSlider)`
  margin-bottom: ${theme.spacing.sm};
  aspect-ratio: 1 / 1;
`

const SliderItem = styled('div', {
  shouldForwardProp: (prop) =>
    !['hasBackgroundColor'].includes(prop.toString()),
})<{ hasBackgroundColor?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  background-color: ${({ hasBackgroundColor }) =>
    hasBackgroundColor && theme.colors.backgroundLight};
`

const Figure = styled.figure`
  margin: 0;
`

const StyledImage = styled(Image, {
  shouldForwardProp: (prop) => !['isProductImage'].includes(prop.toString()),
})<{ isProductImage?: boolean }>`
  max-width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: ${({ isProductImage }) => (isProductImage ? 'contain' : 'cover')};
`

const StyledVideoWithPlaceholder = styled(VideoWithPlaceholder)`
  aspect-ratio: 1 / 1;
  background-color: ${theme.colors.backgroundLight};

  ${VideoPlaceholderImage} {
    aspect-ratio: 1 / 1;
  }
`

const SlideCounter = styled.div`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSizes.lg};
`

const StyledButtonUnstyled = styled(ButtonUnstyled)`
  display: flex;
  align-items: center;
  margin-left: ${theme.spacing.xs};
`

const StyledPlayCircleIcon = styled(PlayCircleIcon)`
  color: ${theme.colors.primary};
  font-size: 15px;
  pointer-events: none;
  margin: 0 ${theme.spacing.xs};
`

interface MediaItems {
  galleryItems: GalleryItem[]
  ambianceItems: ValidAmbianceImage[]
}

interface Props {
  mediaItems: MediaItems
  productLabels: ProductLabelListFragment[] | undefined
}

const ProductPageMediaSlider = ({
  mediaItems,
  productLabels,
  ...other
}: Props) => {
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false)
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0)
  const galleryItems = mediaItems.galleryItems
  const ambianceImages = mediaItems.ambianceItems
  const allMediaItems = [...galleryItems, ...ambianceImages]
  const totalSlidesCount = allMediaItems.length

  const galleryVideos = galleryItems.filter(
    (item) => item.__typename === 'ProductVideo',
  )
  const firstGalleryVideo = galleryVideos.slice(0, 1)[0]

  const onSlideChange = (slideIndex: number) => {
    setCurrentSlideIndex(slideIndex)
  }

  return (
    <Media {...other}>
      {productLabels && productLabels.length !== 0 && (
        <StyledProductLabels productLabels={productLabels} />
      )}

      <StyledComponentSlider slidesToShow={1} onSlideChange={onSlideChange}>
        {allMediaItems.map((mediaItem) => {
          switch (mediaItem.__typename) {
            case 'ProductVideo':
              return (
                <SliderItem key={mediaItem.url}>
                  <StyledVideoWithPlaceholder
                    videoId={getYoutubeIdFromUrl(
                      mediaItem.videoContent?.videoUrl ?? '',
                    )}
                    placeholderImage={mediaItem.url}
                  />
                </SliderItem>
              )
            default:
              return mediaItem.url ? (
                <SliderItem
                  key={mediaItem.url}
                  hasBackgroundColor={mediaItem.__typename === 'ProductImage'}
                >
                  <Figure>
                    <StyledImage
                      url={mediaItem.url}
                      alt={mediaItem.label ?? ''}
                      lazy={false}
                      sizes={theme.imageSizes.productGalleryImage.sizes}
                      isProductImage={mediaItem.__typename === 'ProductImage'}
                    />
                  </Figure>
                </SliderItem>
              ) : null
          }
        })}
      </StyledComponentSlider>

      <SlideCounter>
        <span>
          {currentSlideIndex + 1}/{totalSlidesCount}
        </span>

        {firstGalleryVideo?.__typename === 'ProductVideo' &&
          firstGalleryVideo.videoContent?.videoUrl && (
            <StyledButtonUnstyled
              analyticsContext="product.page.media.slider"
              analyticsName="show.video"
              onClick={() => setShowVideoModal(true)}
            >
              |
              <StyledPlayCircleIcon />
              <Trans>video</Trans>
            </StyledButtonUnstyled>
          )}
      </SlideCounter>

      {firstGalleryVideo?.__typename === 'ProductVideo' &&
        firstGalleryVideo.videoContent?.videoUrl && (
          <VideoModal
            show={showVideoModal}
            close={() => setShowVideoModal(false)}
            videoId={getYoutubeIdFromUrl(
              firstGalleryVideo.videoContent.videoUrl,
            )}
          />
        )}
    </Media>
  )
}

export default ProductPageMediaSlider

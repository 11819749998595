import { Image } from '@emico-react/image'
import styled from '@emotion/styled'
import React, { MutableRefObject, useEffect, useRef } from 'react'

import { GalleryItem, ValidAmbianceImage } from '../lib/customTypes'
import theme from '../theme'
import FullScreenModal from './FullScreenModal'

const MediaWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
`

const Figure = styled.figure`
  margin: 0;
`

const StyledImage = styled(Image)`
  width: 100%;
`

interface ModalItemProps {
  image: GalleryItem | ValidAmbianceImage
  currentImage?: GalleryItem | ValidAmbianceImage
  imageRef?: MutableRefObject<HTMLDivElement | null>
}

const ModalItem = ({ image, currentImage, imageRef }: ModalItemProps) => {
  if (!image.url) {
    return null
  }

  return (
    <Figure ref={image === currentImage ? imageRef : null}>
      <StyledImage
        url={image.url}
        alt={image.label ?? ''}
        lazy={false}
        sizes={theme.imageSizes.fullWidth.sizes}
      />
    </Figure>
  )
}

interface Props {
  galleryImages: GalleryItem[]
  ambianceImages: ValidAmbianceImage[]
  currentImage?: GalleryItem | ValidAmbianceImage
  show: boolean
  close: () => void
}

const ProductPageMediaModal = ({
  galleryImages,
  ambianceImages,
  currentImage,
  show,
  close,
}: Props) => {
  const mediaWrapperRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLDivElement>(null)
  const images = [...galleryImages, ...ambianceImages]

  // Use imageRef's offsetTop to automatically scroll to the image that was clicked to open the modal
  useEffect(() => {
    if (!mediaWrapperRef.current || !imageRef.current) {
      return
    }

    mediaWrapperRef.current.scrollTo({
      top: imageRef.current.offsetTop,
      behavior: 'auto',
    })
  }, [show])

  return (
    <FullScreenModal
      show={show}
      close={close}
      hasAbsoluteHeader
      renderBeforeShow
    >
      <MediaWrapper ref={mediaWrapperRef}>
        {images.map((image) => (
          <ModalItem
            key={image.url}
            image={image}
            currentImage={currentImage}
            imageRef={imageRef}
          />
        ))}
      </MediaWrapper>
    </FullScreenModal>
  )
}

export default ProductPageMediaModal

import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import theme from '../theme'
import SectionHeader from './SectionHeader'

const Section = styled.section`
  margin-bottom: ${theme.spacing['2xl']};
  padding: 0 ${theme.containerPadding};
`

interface Props {
  children: ReactNode
  title?: ReactNode
}

const ProductPageSection = ({ title, children }: Props) => (
  <Section>
    {title && <SectionHeader title={title} hasLargeTitle />}

    {children}
  </Section>
)

export default ProductPageSection
